<template>
    <v-container>
        <v-col cols="12" class="text-center">
            <v-row :class="{ 'mt-10': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                <!-------------- esporta lead  ----------------->

                <v-col cols="4" class="d-flex justify-center">
                    <v-card flat> </v-card>
                </v-col>

                <!-------------- title ----------------->
                <v-col cols="4" class="d-flex justify-center">
                    <h1>
                        {{ $t('sezione', { page: this.$tc('log', 1) }) }}
                    </h1>
                </v-col>

                <!-------------- filter ----------------->
                <v-select :items="availableaction" v-model="filteraction" label="filtra azione" @change="getDataFromApi"></v-select>
            </v-row>
        </v-col>
        <!------------- GRIGLIE ----------------->
        <v-card class="marginScroll">
            <v-data-table
                :headers="headers"
                :items="logs"
                :options.sync="options"
                :server-items-length="totalLogs"
                :loading="loading"
                :search="search"
                class="pt-3"
                :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100], showFirstLastPage: true }"
                :item-class="itemRowBackground"
                @update:options="updateUrl"
                :mobile-breakpoint="1270"
                show-select
                v-model="selectedLogs"
            >
                <template v-slot:top>
                    <v-menu transition="slide-y-transition" bottom v-if="selectedLogs.length && filteraction == 'disabled'" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="ml-6 mt-n2 mb-4" v-bind="attrs" v-on="on"> {{ $tc('azione', 2) }} </v-btn>
                            {{ selectedLogs.length }}
                            {{ $tc('log', 1) }} {{ $tc('selezionato', selectedLogs.length > 1 ? 2 : 1) }}
                        </template>
                        <v-list class="text-uppercase">
                            <!-- 
                            <v-list-item
                                v-if="$permission($auth.utente.role.name, 'FullLeadTable') && (selectedLeads.length == 2 || selectedLeads.length == 3)"
                                @click="merge()"
                            >
                                <v-list-item-title class="ma-5 pointer"
                                    ><v-icon color="black" class="mb-1" left> merge </v-icon> {{ $t('unifica') }}
                                </v-list-item-title>
                            </v-list-item>
                            -->
                            <v-list-item @click="showRestoreDialogSelect">
                                <v-list-item-title class="ma-5 pointer"
                                    ><v-icon color="black" class="mb-1" left> history </v-icon> {{ $t('ripristina') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template #[`item.attributes.lead.data.attributes.Name`]="{ item, value }">
                    <span class="pointer blue--text" @click="openDetails(item)">{{ value }}</span>
                </template>
                <template #[`item.attributes.date`]="{ value }">
                    {{ $moment(value).format('DD/MM/YYYY HH:mm') }}
                </template>
                <template #[`item.attributes.editdate`]="{ value }">
                    {{ !value ? '' : $moment(value).format('DD/MM/YYYY HH:mm') }}
                </template>
            </v-data-table>

            <v-dialog v-model="showRestoreConfirmation" max-width="500">
                <v-card outlined>
                    <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                    <v-card-text class="mt-6, text-center mt-6"> {{ restoreDialogMsg }}</v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn text @click="cancelRestore">{{ $t('annulla') }}</v-btn>
                        <v-spacer></v-spacer>

                        <v-btn color="red" text @click="restoreSelect()">{{ $t('ripristina') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>

        <v-navigation-drawer v-model="infoLead" :class="{ 'full-screen': infoLeadExpand }" width="600px" fixed bottom>
            <v-list nav dense>
                <v-col cols="12">
                    <v-container class="d-flex flex-row mb-n2">
                        <v-btn color="error" @click="closeDetails()"> {{ $t('chiudi') }} </v-btn>
                        <v-spacer></v-spacer>

                        <v-spacer></v-spacer>
                        <v-btn color="success" @click=";(sidebar = !sidebar), (infoLeadExpand = !infoLeadExpand)">
                            {{ !infoLeadExpand ? $t('espandi') : $t('restringi') }}
                        </v-btn>
                    </v-container>
                    <cpLead
                        class="max"
                        v-if="selectedLead"
                        :pid="selectedLead"
                        :pbuttons="{ backArrow: false, merge: false }"
                        :title="false"
                        :sidebar="sidebar"
                        :nobuttons="true"
                    ></cpLead>
                </v-col>
            </v-list>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import cpLead from '@/components/cpLead.vue'

export default {
    name: 'Logs',
    components: { cpLead },
    data() {
        return {
            logs: [],
            totalLogs: 0,
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            timeoutQuery: null,
            search: '',

            showRestoreConfirmation: false,
            restoreDialogCall: null,
            modalFilter: false,
            baseUrl: '',

            headers: [
                {
                    text: this.$tc('data', 1),
                    value: 'attributes.date',
                    field: 'date',
                    sortable: true
                },
                { text: this.$tc('agente', 1), value: 'attributes.agent.data.attributes.Name', field: 'agent', sortable: false },
                {
                    text: this.$tc('lead', 1),
                    value: 'attributes.lead.data.attributes.Name',
                    field: 'lead',
                    sortable: false
                },
                {
                    text: this.$tc('azione', 1),
                    value: 'attributes.action',
                    field: 'action',
                    sortable: true
                }
            ],

            selectedLogs: [],
            changinurl: false,

            addto: [],

            selectDay: null,
            loadingDay: false,

            mergeLeads: [],
            openmerge: false,

            restoreDialogMsg: '',
            filteraction: 'disabled',
            availableaction: ['disabled', 'merged', 'restored'],
            infoLeadExpand: false,
            infoLead: false,
            selectedLead: null,
            sidebar: true
        }
    },
    watch: {},
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'AdminPage')) {
            this.$router.push('/')
        }
        this.baseUrl = window.location.origin
        this.changinurl = true
        if (this.$route.query.page) {
            this.options.page = parseInt(this.$route.query.page)
        }
        if (this.$route.query.items) {
            this.options.itemsPerPage = parseInt(this.$route.query.items)
        }
        if (this.$route.query.sortBy) {
            this.options.sortBy[0] = this.$route.query.sortBy
            if (this.$route.query.sortDesc) {
                this.options.sortDesc[0] = this.$route.query.sortDesc
            }
        }
        /*if (this.$route.query.cap) {
            this.selectCaps = this.$route.query.cap.split(',')
        }

        if (this.$route.query.provincia) {
            await this.getProvinceFromApiId(this.$route.query.provincia)
        }
        if (this.$route.query.citta) {
            await this.getCitiesFromApiId(this.$route.query.citta)
        }
        if (this.$route.query.zona) {
            await this.getZoneFromApiId(this.$route.query.zona)
        }
        if (this.$route.query.cat) {
            this.selectCat = this.$route.query.cat.split(',').map((x) => {
                if (x == 'empty') {
                    return ''
                } else {
                    return x
                }
            })
        }
        if (this.$route.query.sli) {
            let sli = this.$route.query.sli.split(',')
            let c = 0
            for (const x in this.sliders) {
                this.sliders[x].value = sli[c]

                c++
            }
        }*/

        //await this.getDataFromApi()
        //await this.getCitiesFromApi('')
        this.changinurl = false
    },
    methods: {
        closeDetails() {
            this.selectedLead = null
            this.infoLead = false
            this.infoLeadExpand = false
        },
        openDetails(item) {
            this.selectedLead = item.attributes.lead.data.id
            this.infoLead = !this.infoLead
        },
        showRestoreDialogSelect() {
            this.restoreDialogMsg = this.$t('sei-sicuro-di-voler-ripristinare-i-lead-selezionati')
            this.showRestoreConfirmation = true
        },

        clearFilters() {
            // Reset all filter
        },
        async updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true
            if (this.options.page > 1) {
                if (this.$route.query.page != this.options.page) {
                    query.page = this.options.page
                }
            } else {
                delete query.page
            }
            if (this.options.itemsPerPage > 10) {
                if (this.$route.query.items != this.options.itemsPerPage) {
                    query.items = this.options.itemsPerPage
                }
            } else {
                delete query.items
            }
            if (this.options.sortBy.length) {
                if (this.$route.query.sortBy != this.options.sortBy) {
                    query.sortBy = this.options.sortBy
                    if (this.options.sortDesc.length && this.$route.query.sortDesc != this.options.sortDesc) {
                        query.sortDesc = this.options.sortDesc
                    }
                }
            } else {
                delete query.sortBy
                delete query.sortDesc
            }
            /*
            if (this.selectProv.length) {
                if (this.$route.query.provincia != this.selectProv.map((x) => x.id).join(',')) {
                    query.provincia = this.selectProv.map((x) => x.id).join(',')
                }
            } else {
                delete query.provincia
            }

            if (this.selectZona.length) {
                if (this.$route.query.zona != this.selectZona.map((x) => x.id).join(',')) {
                    query.zona = this.selectZona.map((x) => x.id).join(',')
                }
            } else {
                delete query.zona
            }

            if (this.selectCity.length) {
                if (this.$route.query.citta != this.selectCity.map((x) => x.id).join(',')) {
                    query.citta = this.selectCity.map((x) => x.id).join(',')
                }
            } else {
                delete query.citta
            }

            if (this.selectCaps.length) {
                if (this.$route.query.cap != this.selectCaps.join(',')) {
                    query.cap = this.selectCaps.join(',')
                }
            } else {
                delete query.cap
            }
            if (this.selectCat.length) {
                if (
                    this.$route.query.cat !=
                    this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                ) {
                    query.cat = this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                }
            } else {
                delete query.cat
            }
            let sli = []
            let found = false
            for (const x in this.sliders) {
                if (this.sliders[x].value > 0) {
                    found = true
                }
                sli.push(this.sliders[x].value)
            }
            if (found) {
                if (this.$route.query.sli != sli.join(',')) {
                    query.sli = sli.join(',')
                }
            } else {
                delete query.sli
            }
*/
            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})

            await this.getDataFromApi()
            this.changinurl = false
        },

        setFilter() {
            this.options.page = 1
            this.selectedLeads = []
            this.updateUrl()
            this.modalFilter = false
        },

        async getDataFromApi() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            let filters = {}

            //filters[`filters[$and][0][disabled][$eq]`] = false
            let cc = 0
            /*if (!this.filteraction.length) {
                this.filteraction = ['disabled']
            }*/

            //let c = 0
            if (this.filteraction == 'restored') {
                if (!this.headers.find((h) => h.field == 'editdate')) {
                    this.headers.push({
                        text: this.$tc('modifica-data', 1),
                        value: 'attributes.editdate',
                        field: 'editdate',
                        sortable: true
                    })
                }
                if (!this.headers.find((h) => h.field == 'editagent')) {
                    this.headers.push({
                        text: this.$tc('modifica-agente', 1),
                        value: 'attributes.editagent.data.attributes.Name',
                        field: 'editagent',
                        sortable: false
                    })
                }
            } else {
                if (this.headers.find((h) => h.field == 'editdate')) {
                    this.headers = this.headers.filter((h) => h.field != 'editdate')
                }
                if (this.headers.find((h) => h.field == 'editagent')) {
                    this.headers = this.headers.filter((h) => h.field != 'editagent')
                }
            }
            //for (const action of this.filteraction) {
            filters[`filters[$and][${cc}][action][$eqi]`] = this.filteraction
            //c++
            //}
            cc++

            /*if (this.search) {
                filters[`filters[$and][${cc}][Name][$containsi]`] = this.search
                cc++
            }*/
            /*if (this.selectProv.length) {
                let c = 0
                this.selectProv.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][Province][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.selectCity.length) {
                let c = 0
                this.selectCity.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][City][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.selectAgent) {
                let c = 0
                this.selectAgent.zone.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][zones][id][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectCaps?.length) {
                let c = 0
                this.selectCaps.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][CAP][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectCat?.length) {
                let c = 0
                this.selectCat.forEach((x) => {
                    if (x == '') {
                        filters[`filters[$and][${cc}][$or][${c}][Category][$null]`] = true
                        c++
                    }
                    filters[`filters[$and][${cc}][$or][${c}][Category][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectTag?.length) {
                let c = 0
                this.selectTag.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][tags][id][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectDay) {
                let c = 0
                filters[`filters[$and][${cc}][$and][${c}][Opening][${this.selectDay}][morningClosed][$eq]`] = false
                filters[`filters[$and][${cc}][$and][${c}][Opening][${this.selectDay}][afternoonClosed][$eq]`] = false
                cc++
            }
            if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                if (!this.userZones.length) {
                    this.loading = false
                    return
                }
                let c = 0
                this.userZones.forEach((zoneid) => {
                    filters[`filters[$and][${cc}][$or][${c}][zones][id][$eq]`] = zoneid
                    c++
                })
                cc++
                filters[`filters[$and][${cc}][child_leads][id][$null]`] = true
                //filters[`filters[$and][${cc + 1}][$or][0][edit_agents][id][$eq]`] = this.$auth.utente.agent.id
                //filters[`filters[$and][${cc + 1}][$or][1][edit_agents][id][$null]`] = true
            }*/
            let sort = 'date:desc'
            if (sortBy.length) {
                const el = this.headers.find((x) => x.value == sortBy[0])
                sort = el.field.concat(sortDesc[0] ? ':desc' : '')
            }
            const docs = await this.$http.get(`actionlogs`, { populate: ['lead', 'agent', 'editagent'], sort: sort, ...filters })

            this.logs = docs.data || docs
            this.totalLogs = docs.meta.pagination.total ? docs.meta.pagination.total : 0
            this.loading = false
        },

        itemRowBackground(item) {
            /*if (item.validCat == 'missing') {
                return 'tab-lblue'
            } else if (!item.validCap) {
                return 'tab-purple'
            } else if (item.ischild) {
                return 'tab-red'
            } else if (item.isagentlead) {
                return 'tab-yellow'
            }*/
        },
        async restoreSelect() {
            if (!this.$permission(this.$auth.utente.role.name, 'restoreLead')) {
                return
            }

            try {
                for (const log of this.selectedLogs) {
                    //await this.$http.delete(`leads/${lead.id}`)
                    await this.$http.put(`leads/${log.attributes.lead.data.id}`, {
                        data: { disabled: false }
                    })
                    await this.$http.put(`actionlogs/${log.id}`, {
                        data: { editagent: this.$auth.utente.agent.id, action: 'restored', editdate: this.$moment().format() }
                    })
                }

                this.getDataFromApi()
                this.selectedLogs = []
                this.cancelRestore()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('successo'),
                    text: this.$t('leads-selezionati-ripristinati-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.error(error)
                this.cancelRestore()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-il-ripristino-dei-leads-selezionati'),
                    type: 'error'
                })
            }
        },
        cancelRestore() {
            this.showRestoreConfirmation = false
            this.restoreDialogMsg = ''
        }
    }
}
</script>
<style>
/* Stile CSS personalizzato */
.no-underline {
    text-decoration: none;
}
.icon {
    width: 20px;
}
h4 {
    text-align: center;
    text-transform: uppercase;
}
</style>
