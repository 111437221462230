<template>
    <v-container>
        <loading v-model="loading" />
        <!-------------- TITOLO
                    ----------------->
        <v-row>
            <v-col cols="12">
                <h1 class="pt-16 text-center">
                    <span v-show="!editing && !newz">{{ $t('informazioni') }} </span>
                    <span v-show="newz">{{ $t('creazione') }} </span>
                    <span v-show="editing && !newz">{{ $t('modifica') }} </span>
                    {{ $tc('zona', 1) }}
                </h1>
            </v-col>
        </v-row>
        <!--------------  creazione/modifica agente
                       ----------------->

        <v-card class="overflow-hidden" color="primary" dark>
            <v-toolbar color="primary darken-1">
                <a @click="$router.go(-1)">
                    <v-icon class="mr-3 ml-4">arrow_back</v-icon>
                </a>

                <v-spacer></v-spacer>
                <v-btn color="green ml-2" fab small @click="duplicate()" v-show="!editing">
                    <v-icon>content_copy</v-icon>
                </v-btn>
                <v-btn color="green ml-2" fab small @click="exportZone()" v-show="!editing">
                    <v-icon>download</v-icon>
                </v-btn>
                <v-btn color="green ml-2" fab small @click="Edit(true)" v-show="!editing">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                    color="red "
                    class="ml-2"
                    fab
                    small
                    @click="showDeleteConfirmation = true"
                    v-show="!editing"
                    v-if="$permission($auth.utente.role.name, 'deleteZone')"
                >
                    <v-icon>delete</v-icon>
                </v-btn>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="success" v-show="newz" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                    <v-btn color="success" v-show="editing && !newz" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                    <v-btn color="error" v-show="editing && !newz" @click="Edit(false)" :disabled="loading"> {{ $t('annulla') }} </v-btn>
                </v-card-actions>
            </v-toolbar>
            <v-card-text>
                <v-text-field v-model="Zona.Name" :label="$t('nome')" class="pa-4" outlined :readonly="!editing"></v-text-field>
                <v-row>
                    <v-col cols="12" md="4" class="map" @click="closeDetails()" v-if="editing">
                        <l-map style="width: 100%; height: 100%" :zoom="zoom" :center="center" :options="options" ref="myMap" @ready="mapReady()">
                            <l-control-layers position="topright"></l-control-layers>
                            <l-control position="topleft">
                                <v-btn x-small class="button_location" @click="getCoordinate()"> <v-icon size="22" class="">my_location</v-icon></v-btn>
                            </l-control>
                            <l-tile-layer
                                v-for="tileProvider in tileProviders"
                                :key="tileProvider.name"
                                :name="tileProvider.name"
                                :visible="tileProvider.visible"
                                :url="tileProvider.url"
                                :attribution="tileProvider.attribution"
                                layer-type="base"
                            />

                            <l-marker v-for="(marker, index) in markers" :lat-lng="marker.coordinate" :key="index" :icon="iconblue">
                                <l-popup v-if="marker.popup">
                                    <div>
                                        <h3>{{ marker.popup }}</h3>
                                    </div>
                                    <div class="text-center">
                                        <v-btn small rounded color="blue" class="" @click="addRuleMap(marker)">
                                            <v-icon class="pa-2">add_location_alt</v-icon>
                                        </v-btn>
                                    </div>
                                </l-popup>
                            </l-marker>
                        </l-map>
                    </v-col>
                    <v-col cols="12" md="4" v-show="editing">
                        <!-- V-CARD MODIFICA REGOLE -->
                        <v-card max-width="500" class="mx-auto">
                            <v-app-bar dark color="#black ">
                                <v-card-title :class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-h6'">
                                    {{ editingRule ? $t('modifica') : $t('aggiungi') }} {{ $tc('regola', 2) }}:</v-card-title
                                >

                                <v-spacer></v-spacer>
                            </v-app-bar>

                            <v-card-text>
                                <v-text-field v-model="ruletmp.name" :label="$t('nome-regola')" class="" outlined :readonly="!editing"></v-text-field>

                                <v-autocomplete
                                    v-model="ruletmp.prov"
                                    :items="Province"
                                    :label="$tc('provincia', 1)"
                                    item-text="name"
                                    return-object
                                    outlined
                                    clearable
                                    @change="updateCities"
                                ></v-autocomplete>
                                <v-autocomplete
                                    v-if="tmpProv"
                                    v-model="ruletmp.cities"
                                    :label="$t('citta')"
                                    :items="Citta"
                                    item-text="name"
                                    return-object
                                    outlined
                                    multiple
                                    chips
                                    clearable
                                    deletable-chips
                                    @change="updateCaps"
                                >
                                    <template v-slot:selection="{ index }">
                                        <span v-if="index === 0" class="mr-1 text-capitalize">{{ ruletmp.cities.length }} {{ $tc('selezionato', 2) }}</span>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggleCities">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ allSelection ? $t('deseleziona-tutto') : $t('seleziona-tutto') }} </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-autocomplete>
                                <v-autocomplete
                                    v-if="tmpProv && tmpCit.length > 0 && Cap.length"
                                    v-model="ruletmp.caps"
                                    :label="$t('cap')"
                                    :items="Cap"
                                    outlined
                                    multiple
                                    chips
                                    clearable
                                    deletable-chips
                                >
                                    <template v-slot:selection="{ index }">
                                        <span v-if="index === 0" class="mr-1 text-capitalize">{{ ruletmp.caps.length }} {{ $tc('selezionato', 2) }}</span>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggleCaps">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ allSelection ? $t('deseleziona-tutto') : $t('seleziona-tutto') }} </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-autocomplete>
                                <v-btn color="success" v-show="editing" :disabled="!ruletmp.cities.length" @click="addRule"
                                    >{{ editingRule ? $t('modifica') : $t('aggiungi') }} {{ $tc('regola', 1) }}
                                </v-btn>
                                <v-btn class="ml-3" color="error" :disabled="!ruletmp.caps.length" v-show="editingRule" @click="cancelChanges">{{
                                    $t('annulla-modifiche')
                                }}</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- V-CARD LISTA REGOLE -->

                    <v-col cols="12" md="4">
                        <v-card max-width="500" class="mx-auto">
                            <v-app-bar dark color="#black " class="text-capitalize">
                                <v-card-title :class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-h6'">{{ $tc('regola', 2) }}:</v-card-title>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-1" color="white" outlined pill> {{ $t('leads-associati') }}: {{ leads }} </v-chip>
                            </v-app-bar>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-card class="mb-5" color="#385F73" dark v-for="(rule, index) in Zona.Rules" :key="index">
                                            <v-card-title class="text-h5">
                                                <span class="text-h5 text-capitalize" v-show="rule.name">{{ $t('nome-regola') }}: {{ rule.name }} <br /></span>
                                                <v-chip class="ma-1" color="white" outlined pill>
                                                    {{ $t('leads-associati') }}: {{ rule.count }} <v-icon v-if="rule.count == null">hourglass_top</v-icon>
                                                </v-chip>
                                            </v-card-title>
                                            <v-card-text color="white" class="text-h5 text-capitalize">
                                                {{ $tc('provincia', 1) }}: {{ rule.prov.name }}
                                            </v-card-text>
                                            <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#385F73" class="text-h5"> {{ $t('citta') }}</v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        {{ rule.cities.map((x) => x.name).join(',') }}
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                            <v-expansion-panels v-show="rule.caps.length">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#385F73" class="text-h5"> {{ $t('cap') }}</v-expansion-panel-header>
                                                    <v-expansion-panel-content> {{ rule.caps.join(',') }} </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>

                                            <v-card-actions>
                                                <v-btn color="primary" v-show="editing" @click="editRule(index)">{{ $t('modifica') }}</v-btn>
                                                <v-btn v-show="editing" class="ma-3" color="error" @click="openDialog(index)">{{ $t('elimina') }}</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="success" v-show="newz" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                <v-btn color="success" v-show="editing && !newz" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                <v-btn color="error" v-show="editing && !newz" @click="Edit(false)" :disabled="loading"> {{ $t('annulla') }} </v-btn>
            </v-card-actions>
        </v-card>
        <!-- messaggio conferma eliminazione regola  -->

        <v-dialog v-model="dialog" width="500" transition="dialog-bottom-transition">
            <v-card>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6 text-center"> {{ $t('sei-sicuro-di-voler-eliminare-la-regola') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="cancel">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn text color="red" @click="deleteRule()">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="text-center mt-6"> {{ $t('sei-sicuro-di-voler-eliminare-la-zona') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="showDeleteConfirmation = false">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="Delete()">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import loading from '@/components/loading.vue'
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet/dist/leaflet.css'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LControlLayers, LControl } from 'vue2-leaflet'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
    components: {
        loading,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LControlLayers,
        LControl
    },
    name: 'Zone',
    data() {
        return {
            Zona: {
                id: null,
                Name: null,
                Rules: []
            },
            ruletmp: { name: null, prov: null, cities: [], caps: [] },
            Province: [],
            Citta: [],
            Cap: [],
            editing: null,
            newz: null,
            loading: false,
            ZonaOriginal: {},
            allCapSelected: false,
            dialog: false,
            removeIndex: -1,
            leads: 0,
            editingRule: false,
            editingRuleIndex: -1,
            modified: false,
            showDeleteConfirmation: false,
            zoom: 14,
            center: [45.0841078, 7.4147201],
            tileProviders: [
                {
                    name: 'OpenStreetMap',
                    visible: true,
                    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                },
                {
                    name: 'OpenTopoMap',
                    visible: false,
                    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                    attribution:
                        'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                },
                {
                    name: 'Satellite',
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                }
            ],
            options: {
                minZoom: 5,
                maxZoom: 17
            },
            markers: [],
            selectedMarker: null,
            titleMarker: null,
            mapInfo: {
                east: null,
                west: null,
                north: null,
                south: null,
                center: null
            },
            map: null,
            route: null,
            currLocation: { lat: 0, lng: 0 },
            iconMe: L.icon({
                iconUrl: 'img/marker/marker_agent.png',
                iconRetinaUrl: 'img/marker/marker_agent_2x.png',
                iconSize: [26, 51],
                iconAnchor: [43, 51]
            }),

            iconblue: L.icon({
                iconUrl: '../img/marker/marker_blue.png',
                iconRetinaUrl: '../img/marker/marker_blue_2x.png',
                shadowUrl: '../img/marker/marker-shadow.png',
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            })
        }
    },
    computed: {
        tmpProv() {
            return this.ruletmp.prov
        },
        tmpCit() {
            return this.ruletmp.cities
        },
        rules() {
            return this.Zona.Rules
        },
        isFormValid() {
            return !!this.Zona.Name && this.Zona.Rules.length > 0
        },
        allSelection: {
            get() {
                return (
                    this.Citta.length > 0 && this.ruletmp.cities.length === this.Citta.length,
                    this.Cap.length > 0 && this.ruletmp.caps.length === this.Cap.length
                )
            },
            set(valore) {
                if (valore) {
                    this.ruletmp.cities = JSON.parse(JSON.stringify(this.Citta))
                } else {
                    this.ruletmp.cities = []
                }
            }
        }
    },
    watch: {
        rules: {
            handler() {
                //if (this.editingRule) return
                if (!this.rules.length) {
                    this.leads = 0
                    return
                }
                this.getNLeadsRules()
            },
            deep: true
        }
    },
    props: {
        pedit: { type: Boolean, default: false },
        pnew: { type: Boolean, default: false }
    },
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'ZonePage')) {
            this.$router.push('/')
            return
        }
        this.newz = this.pnew
        if (this.newz) this.editing = true
        if (this.$route.params.id) {
            const doc = await this.$http
                .get(`zones/${this.$route.params.id}`, {
                    populate: []
                })
                .catch((err) => {
                    console.log('errore', err)
                })
            if (!doc) {
                this.$router.push('/')
                return
            }

            this.Zona = {
                id: doc.data.id,
                Name: doc.data.attributes.Name,
                Rules: doc.data.attributes.Rules
            }
            this.Zona.Rules.forEach(async (x) => {
                x.count = await this.getNLeadsRule(x)
            })
            if (this.pedit) {
                this.Edit(true)
            }
        }

        const p = await this.$http.get(`provinces`, { 'pagination[pageSize]': 200 })
        this.Province = p.data.map((x) => {
            return { id: x.id, name: x.attributes.Name }
        })
    },
    methods: {
        async getNLeadsRules() {
            this.leads = await this.$http.post(`leadsByRules`, this.rules)
        },
        async getNLeadsRule(rule) {
            return await this.$http.post(`leadsByRule`, rule)
        },
        cancel() {
            this.dialog = false
        },
        async editRule(index) {
            this.editingRuleIndex = index
            this.editingRule = true
            let rule = this.Zona.Rules[index]
            this.ruletmp = JSON.parse(JSON.stringify(rule))
            await this.getCities(this.tmpProv)
            this.getCaps(this.tmpCit)
        },
        cancelChanges() {
            if (this.modified) {
                if (confirm(this.$t('sei-sicuro-di-voler-annullare-le-modifiche'))) {
                    if (this.editingRule) {
                        this.ruletmp = JSON.parse(JSON.stringify(this.Zona.Rules[this.editingRuleIndex]))
                        this.editingRule = false
                        this.editingRuleIndex = -1
                    } else {
                        this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                    }
                    this.ruletmp.prov = null
                    this.ruletmp.cities = []
                    this.ruletmp.caps = []
                    this.modified = false
                }
            } else {
                if (this.editingRule) {
                    this.ruletmp = JSON.parse(JSON.stringify(this.Zona.Rules[this.editingRuleIndex]))
                    this.editingRule = false
                    this.editingRuleIndex = -1
                } else {
                    this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                }
                this.ruletmp.prov = null
                this.ruletmp.cities = []
                this.ruletmp.caps = []
            }
        },

        openDialog(index) {
            this.removeIndex = index
            this.dialog = true
        },
        deleteRule() {
            if (this.removeIndex > -1) {
                this.Zona.Rules.splice(this.removeIndex, 1)
                this.removeIndex = -1
            }
            this.dialog = false
        },
        Edit(stato) {
            if (stato) {
                this.ZonaOriginal = JSON.parse(JSON.stringify(this.Zona))
            } else {
                if (this.modified) {
                    if (confirm(this.$t('sei-sicuro-di-voler-annullare-le-modifiche'))) {
                        this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                        this.modified = false
                    }
                } else {
                    this.Zona = JSON.parse(JSON.stringify(this.ZonaOriginal))
                }
            }
            this.editing = stato
        },
        async Delete() {
            try {
                // Effettua la chiamata HTTP per eliminare il lead
                await this.$http.delete(`zones/${this.Zona.id}`)

                // Chiudi il dialogo di conferma ed esegui altre azioni
                this.showDeleteConfirmation = false
                this.$router.push('/zones')
            } catch (error) {
                // Gestisci l'errore durante l'eliminazione del lead
                console.error(error)

                // Mostra una notifica di errore
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-della-zona'),
                    type: 'error'
                })
                this.showDeleteConfirmation = false
            }
        },
        async addRule() {
            if (this.editingRule) {
                this.Zona.Rules.splice(this.editingRuleIndex, 1, this.ruletmp)
                this.Zona.Rules[this.editingRuleIndex].count = await this.getNLeadsRule(this.ruletmp)
                this.editingRule = false
                this.editingRuleIndex = -1
            } else {
                this.Zona.Rules.push({ ...this.ruletmp, count: await this.getNLeadsRule(this.ruletmp) })
            }

            this.ruletmp = { prov: null, cities: [], caps: [] }
            this.modified = true
        },
        async updateCities() {
            if (!this.tmpProv) {
                this.Citta = []
                this.ruletmp.cities = []
                return
            }

            await this.getCities(this.tmpProv)
            this.toggleCities()
        },
        updateCaps() {
            if (!this.tmpCit.length) {
                this.Cap = []
                this.ruletmp.caps = []
                return
            }
            this.getCaps(this.tmpCit)
            this.ruletmp.caps = this.Cap.slice()
        },
        async getCities(prov) {
            this.Citta = []
            //this.ruletmp.cities = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'populate': ['Province'],
                    'filters': { Province: { id: { $eq: prov.id } } },
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name'
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            this.Citta = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, cap: x.attributes.Cap }
            })
        },
        getCaps(cities) {
            const idcit = cities.map((x) => {
                return x.id
            })
            this.Cap = []
            //this.ruletmp.caps = []
            let cap = new Set()
            this.Citta.forEach((x) => {
                if (!idcit.includes(x.id)) {
                    return
                }
                if (x.cap.length == 1) return
                x.cap.forEach((c) => {
                    cap.add(c)
                })
            })
            this.Cap = [...cap].sort()
        },
        toggleCaps() {
            if (this.Cap.length == this.ruletmp.caps.length) {
                this.ruletmp.caps = []
                this.allCapSelected = false
            } else {
                this.ruletmp.caps = this.Cap.slice()
                this.allCapSelected = true
            }
        },
        toggleCities() {
            if (this.Citta.length == this.ruletmp.cities.length) {
                this.ruletmp.cities = []
            } else {
                this.ruletmp.cities = JSON.parse(JSON.stringify(this.Citta))
            }
            this.updateCaps()
        },
        async Save() {
            if (!this.isFormValid) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('il-nome-o-una-regola-non-sono-stati-inseriti'),
                    type: 'error'
                })
                return
            }
            try {
                if (this.loading) return
                this.loading = true

                const zone = await this.$http.put(`zones/${this.Zona.id}`, {
                    data: {
                        Name: this.Zona.Name,
                        Rules: this.Zona.Rules
                    }
                })

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                this.editing = false
                this.loading = false
            }
        },
        async Create() {
            if (!this.isFormValid) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('il-nome-o-una-regola-non-sono-stati-inseriti'),
                    type: 'error'
                })
                return
            }
            try {
                if (this.loading) return

                this.loading = true

                const agent = await this.$http.post(`zones`, {
                    data: {
                        Name: this.Zona.Name,
                        Rules: this.Zona.Rules
                    }
                })

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
                this.$router.push('/zones') // Replace "/sezione-zone" with the actual route path
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },
        exportZone() {
            let txt = `${this.$t('nome-zona')}: ${this.Zona.Name} (${this.leads})\n\n`

            this.Zona.Rules.forEach((rule, index) => {
                txt += `${this.$t('nome-regola')}: ${rule.name} (${rule.count})\n`
                txt += `${this.$tc('provincia', 1)}: ${rule.prov.name} \n`
                txt += `${this.$t('citta')}: \n`
                rule.cities.forEach((y) => {
                    txt += `${y.name} \n`
                })
                if (rule.caps.length) {
                    txt += `\nCAP: \n`
                    rule.caps.forEach((y) => {
                        txt += `${y} \n`
                    })
                }
                txt += `\n`
            })

            const anchor = document.createElement('a')
            anchor.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt)
            anchor.target = '_blank'
            anchor.download = `zona-${this.Zona.Name}.txt`
            anchor.click()
        },
        duplicate() {
            this.$router.push({
                path: '/zone/new',
                replace: true
            })
            this.newz = true
            this.Zona.id = null
            this.Zona.Name = null
            this.editing = true
        },
        async mapReady() {
            this.map = this.$refs.myMap.mapObject
            this.mapInfo = {
                east: this.map.getBounds().getEast(),
                west: this.map.getBounds().getWest(),
                north: this.map.getBounds().getNorth(),
                south: this.map.getBounds().getSouth(),
                center: this.map.getCenter()
            }

            this.getCitiesMap()
            var self = this
            this.map.on('moveend', function onDragEnd() {
                if (self.autozoom) {
                    self.autozoom = false
                    return
                }
                self.mapInfo = {
                    east: self.map.getBounds().getEast(),
                    west: self.map.getBounds().getWest(),
                    north: self.map.getBounds().getNorth(),
                    south: self.map.getBounds().getSouth(),
                    center: self.map.getCenter()
                }
                self.getCitiesMap()
            })
        },
        closeDetails() {
            this.selectedMarker = null
        },
        async getCitiesMap() {
            let filters = {}
            filters[`filters[$and][0][disabled][$eq]`] = false
            filters[`filters[$and][1][Latitude][$gte]`] = this.mapInfo.south
            filters[`filters[$and][2][Latitude][$lte]`] = this.mapInfo.north
            filters[`filters[$and][3][Longitude][$gte]`] = this.mapInfo.west
            filters[`filters[$and][4][Longitude][$lte]`] = this.mapInfo.east
            let cc = {}
            let ci = []
            let pa = 1
            do {
                cc = await this.$http.get(`leads`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'fields': ['Name', 'Latitude', 'Longitude'],
                    'populate': ['City', 'Province'],
                    'sort': 'Name',
                    ...filters
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)

            const grouped = this.$groupItemBy(ci, 'attributes.City.data.id')
            let markers = []
            for (const c in grouped) {
                if (
                    !grouped[c].length ||
                    grouped[c][0].attributes.City.data.attributes.latitude == null ||
                    grouped[c][0].attributes.City.data.attributes.longitude == null
                ) {
                    continue
                }
                markers.push({
                    id: c,
                    coordinate: latLng(grouped[c][0].attributes.City.data.attributes.latitude, grouped[c][0].attributes.City.data.attributes.longitude),
                    popup: grouped[c][0].attributes.City.data.attributes.Name,
                    details: {
                        city: {
                            name: grouped[c][0].attributes.City.data.attributes.Name,
                            id: grouped[c][0].attributes.City.data.id,
                            cap: grouped[c][0].attributes.City.data.attributes.Cap
                        },
                        province: { name: grouped[c][0].attributes.Province.data.attributes.Name, id: grouped[c][0].attributes.Province.data.id }
                    }
                })
            }
            this.markers = markers
        },
        async addRuleMap(marker) {
            this.ruletmp.prov = marker.details.province
            await this.updateCities()
            this.ruletmp.cities = [marker.details.city]
        }
    }
}
</script>
<style>
.v-list-item__content {
    align-items: center;
    align-self: center;
    display: block !important;
    flex-wrap: wrap;
    flex: 1 1;
    overflow: hidden;
    padding: 12px 0;
}
.map {
    min-height: 400px;
}
</style>
