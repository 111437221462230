<template>
    <v-container>
        <!--ORARIO in visualizzazione-->
        <v-simple-table v-show="!editing">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">{{ $tc('giorno', 2) }}</th>
                        <th class="text-left">{{ $t('mattina') }}</th>
                        <th class="text-left">{{ $t('pomeriggio') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="key in Object.keys(opening).sort((a, b) => order.indexOf(a) - order.indexOf(b))" :key="key">
                        <template v-if="key != 'id' && !['sat', 'sun'].includes(key)">
                            <td :class="opening[key].hourContinued ? 'font-weight-bold' : ''">{{ $getDayName(key) }}</td>

                            <td v-if="!opening[key].morningClosed">{{ opening[key].moTimeOpen }} - {{ opening[key].moTimeClose }}</td>
                            <td v-else>{{ $t('chiuso') }}</td>
                            <td v-if="!opening[key].afternoonClosed">{{ opening[key].afTimeOpen }} - {{ opening[key].afTimeClose }}</td>
                            <td v-else>{{ $t('chiuso') }}</td>
                        </template>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <!--ORARIO in modifica-->

        <v-simple-table v-show="editing">
            <div class="d-flex justify-center mt-3 mb-n2">
                <v-btn color="success" class="justify-center" @click="copyDialog = true"> {{ $t('copia-orari') }} </v-btn>
            </div>
            <v-container v-for="key in Object.keys(opening).sort((a, b) => order.indexOf(a) - order.indexOf(b))" :key="key">
                <div v-if="key != 'id' && !['sat', 'sun'].includes(key)">
                    <!--titolo orario-->
                    <div class="border-hour pa-3">
                        <v-row no-gutters class="d-flex align-center">
                            <v-col cols="4" sm="3" class="d-flex align-center">
                                <h1 class="d-none d-sm-flex">{{ $getDayName(key) }}</h1>
                                <h4 class="d-flex d-sm-none">{{ $getDayName(key) }}</h4>
                                <v-btn
                                    text
                                    @click="
                                        ;(opening[key].afternoonClosed = !opening[key].afternoonClosed),
                                            (opening[key].morningClosed = !opening[key].morningClosed)
                                    "
                                >
                                    <v-icon class="white--text"> lock </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4" v-if="fullClose(opening[key])" class="d-none d-sm-flex text-center">
                                <h3 class="red--text">{{ $t('chiuso-tutto-il-giorno') }}</h3>
                            </v-col>
                            <v-col cols="3" sm="2" class="d-none d-sm-flex justify-center" v-if="!fullClose(opening[key])">
                                <v-switch
                                    v-if="!opening[key].hourContinued"
                                    v-model="opening[key].morningClosed"
                                    class="v-input--reverse"
                                    :label="$t('mattina')"
                                    color="error"
                                ></v-switch>
                            </v-col>
                            <v-col cols="4" sm="2" class="text-center"> </v-col>
                            <v-col cols="3" class="d-none d-sm-flex justify-center" v-if="!fullClose(opening[key])">
                                <v-switch
                                    v-if="!opening[key].hourContinued"
                                    v-model="opening[key].afternoonClosed"
                                    class="v-input--reverse"
                                    :label="$t('pomeriggio')"
                                    color="error"
                                ></v-switch>
                            </v-col>

                            <v-col
                                cols="4"
                                sm="2"
                                class="d-flex flex-column justify-right align-end text-right"
                                v-if="!opening[key].afternoonClosed && !opening[key].morningClosed"
                            >
                                <p class="d-none d-sm-flex pa-0 ma-0 text-capitalize">{{ $t('orario-continuato') }}</p>
                                <p class="d-flex d-sm-none pa-0 ma-0 text-capitalize">{{ $t('continuato') }}</p>
                                <v-switch v-model="opening[key].hourContinued" @click="setContinued(opening[key])" class="pa-0 ma-0" color="error"></v-switch>
                            </v-col>
                        </v-row>
                        <!--inserimento orario-->

                        <v-row>
                            <v-col cols="12" v-if="fullClose(opening[key])" class="d-flex d-sm-none text-center">
                                <h3 class="red--text">{{ $t('chiuso-tutto-il-giorno') }}</h3>
                            </v-col>
                            <v-col cols="12" class="d-flex d-sm-none justify-center mt-n5 mb-n8" v-if="!fullClose(opening[key])">
                                <v-switch
                                    v-if="!opening[key].hourContinued"
                                    v-model="opening[key].morningClosed"
                                    class="v-input--reverse"
                                    :label="$t('mattina')"
                                    color="error"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="opening[key].morningClosed && !opening[key].afternoonClosed" class="text-center">
                                <h3 class="red--text">{{ $t('chiuso-il-mattino') }}</h3>
                            </v-col>

                            <v-col cols="6" sm="3" v-if="!opening[key].morningClosed">
                                <!--apertura mattino-->
                                <v-menu
                                    ref="menuMorningOpen"
                                    v-model="opening[key].mopen"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="300px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="opening[key].moTimeOpen"
                                            :label="$t('apertura')"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :class="{ 'ml-7': $vuetify.breakpoint.smAndUp }"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        :allowed-minutes="allowedMinutes"
                                        format="24hr"
                                        v-model="opening[key].moTimeOpen"
                                        :max="opening[key].moTimeClose || '13:00'"
                                        full-width
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="opening[key].mopen = false">
                                            {{ $t('ok') }}
                                        </v-btn></v-time-picker
                                    >
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="6"
                                sm="3"
                                :class="{ 'pl-7': $vuetify.breakpoint.smAndUp }"
                                v-if="opening[key].hourContinued && !fullClose(opening[key])"
                            >
                                <!--chiusura pomeriggio-->
                                <v-menu
                                    ref="menumorningClosed"
                                    v-model="opening[key].aclose"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="300px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="opening[key].afTimeClose"
                                            :label="$t('chiusura')"
                                            readonly
                                            :class="{ 'mr-7': $vuetify.breakpoint.smAndUp }"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        :allowed-minutes="allowedMinutes"
                                        format="24hr"
                                        v-model="opening[key].afTimeClose"
                                        :min="opening[key].afTimeOpen || '13:00'"
                                        full-width
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="opening[key].aclose = false"> {{ $t('ok') }} </v-btn>
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="6"
                                sm="3"
                                :class="{ 'pl-7': $vuetify.breakpoint.smAndUp }"
                                v-if="!opening[key].morningClosed && !opening[key].hourContinued"
                            >
                                <!--chiusura mattino-->
                                <v-menu
                                    ref="menumorningClosed"
                                    v-model="opening[key].mclose"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="300px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="opening[key].moTimeClose"
                                            :label="$t('chiusura')"
                                            readonly
                                            clearable
                                            :class="{ 'mr-7': $vuetify.breakpoint.smAndUp }"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        :allowed-minutes="allowedMinutes"
                                        format="24hr"
                                        v-model="opening[key].moTimeClose"
                                        :min="opening[key].moTimeOpen"
                                        max="13:00"
                                        full-width
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="opening[key].mclose = false">
                                            {{ $t('ok') }}
                                        </v-btn></v-time-picker
                                    >
                                </v-menu>
                            </v-col>
                            <v-col cols="12" class="d-flex d-sm-none justify-center mt-n5 mb-n8" v-if="!fullClose(opening[key])">
                                <v-switch
                                    v-if="!opening[key].hourContinued"
                                    v-model="opening[key].afternoonClosed"
                                    class="v-input--reverse"
                                    :label="$t('pomeriggio')"
                                    color="error"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="opening[key].afternoonClosed && !opening[key].morningClosed" class="text-center">
                                <h3 class="red--text">{{ $t('chiuso-il-pomeriggio') }}</h3>
                            </v-col>

                            <v-col cols="6" sm="3" :class="{}" v-if="!opening[key].afternoonClosed && !opening[key].hourContinued">
                                <!--apertura pomeriggio-->
                                <v-menu
                                    ref="menuMorningOpen"
                                    v-model="opening[key].aopen"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="300px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="opening[key].afTimeOpen"
                                            :label="$t('apertura')"
                                            readonly
                                            clearable
                                            :class="{ 'ml-7': $vuetify.breakpoint.smAndUp }"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        :allowed-minutes="allowedMinutes"
                                        format="24hr"
                                        v-model="opening[key].afTimeOpen"
                                        min="13:00"
                                        :max="opening[key].afTimeClose"
                                        full-width
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="opening[key].aopen = false">
                                            {{ $t('ok') }}
                                        </v-btn></v-time-picker
                                    >
                                </v-menu>
                            </v-col>

                            <v-col cols="6" sm="3" v-if="!opening[key].afternoonClosed && !opening[key].hourContinued">
                                <!--chiusura pomeriggio-->
                                <v-menu
                                    ref="menumorningClosed"
                                    v-model="opening[key].aclose"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="300px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="opening[key].afTimeClose"
                                            :label="$t('chiusura')"
                                            readonly
                                            :class="{ 'mr-7': $vuetify.breakpoint.smAndUp }"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        :allowed-minutes="allowedMinutes"
                                        format="24hr"
                                        v-model="opening[key].afTimeClose"
                                        :min="opening[key].afTimeOpen || '13:00'"
                                        full-width
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="opening[key].aclose = false"> {{ $t('ok') }} </v-btn>
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-container>
        </v-simple-table>

        <!--ORARIO in edit
        <v-simple-table fixed-header dense class="pb-4">
            <template v-slot:default>
                <v-row v-for="key in Object.keys(opening).sort((a, b) => order.indexOf(a) - order.indexOf(b))" :key="key">
                    <template v-if="key != 'id' && !['sat', 'sun'].includes(key)">
                        <tr style="min-width: 100%" max-height="160">
                            <v-col cols="12">
                                <h2 class="text-center">{{ $getDayName(key) }}</h2>
                            </v-col>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-col>
                                        <h5 class="pl-6">
                                            MATTINA
                                            <v-switch class="d-flex justify-center" v-model="opening[key].morningClosed" label="Chiuso" hide-details></v-switch>
                                        </h5>
                                    </v-col>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-menu
                                                ref="menuMorningOpen"
                                                v-model="opening[key].mopen"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="300px"
                                                min-width="300px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-show="!opening[key].morningClosed"
                                                        v-model="opening[key].moTimeOpen"
                                                        label="Apertura"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="ml-7"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    :allowed-minutes="allowedMinutes"
                                                    format="24hr"
                                                    v-model="opening[key].moTimeOpen"
                                                    :max="opening[key].moTimeClose || '13:00'"
                                                    full-width
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="opening[key].mopen = false"> OK </v-btn></v-time-picker
                                                >
                                            </v-menu>
                                        </v-col>

                                        <v-col cols="6">
                                            <v-menu
                                                ref="menumorningClosed"
                                                v-model="opening[key].mclose"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="300px"
                                                min-width="300px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-show="!opening[key].morningClosed"
                                                        v-model="opening[key].moTimeClose"
                                                        label="Chiusura"
                                                        readonly
                                                        clearable
                                                        class="mr-7"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    :allowed-minutes="allowedMinutes"
                                                    format="24hr"
                                                    v-model="opening[key].moTimeClose"
                                                    :min="opening[key].moTimeOpen"
                                                    max="13:00"
                                                    full-width
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="opening[key].mclose = false"> OK </v-btn></v-time-picker
                                                >
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-col>
                                        <h5 class="pl-6">
                                            POMERIGGIO

                                            <v-switch
                                                class="d-flex justify-center"
                                                v-model="opening[key].afternoonClosed"
                                                label="Chiuso"
                                                hide-details
                                            ></v-switch>
                                        </h5>
                                    </v-col>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-menu
                                                ref="menuMorningOpen"
                                                v-model="opening[key].aopen"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="300px"
                                                min-width="300px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-show="!opening[key].afternoonClosed"
                                                        v-model="opening[key].afTimeOpen"
                                                        label="Apertura"
                                                        readonly
                                                        clearable
                                                        class="ml-7"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    :allowed-minutes="allowedMinutes"
                                                    format="24hr"
                                                    v-model="opening[key].afTimeOpen"
                                                    min="13:00"
                                                    :max="opening[key].afTimeClose"
                                                    full-width
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="opening[key].aopen = false"> OK </v-btn></v-time-picker
                                                >
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu
                                                ref="menumorningClosed"
                                                v-model="opening[key].aclose"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-show="!opening[key].afternoonClosed"
                                                        v-model="opening[key].afTimeClose"
                                                        label="Chiusura"
                                                        readonly
                                                        class="mr-7"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    :allowed-minutes="allowedMinutes"
                                                    format="24hr"
                                                    v-model="opening[key].afTimeClose"
                                                    :min="opening[key].afTimeOpen || '13:00'"
                                                    full-width
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="opening[key].aclose = false"> OK </v-btn>
                                                </v-time-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </tr>
                    </template>
                </v-row>
            </template>
        </v-simple-table>-->

        <v-dialog v-model="copyDialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('copia-orari') }} </v-card-title>

                <v-card-text>
                    <v-autocomplete v-model="day" :label="$t('seleziona-giorno-da-copiare')" :items="listDays" class="pa-4" outlined></v-autocomplete>
                    <v-autocomplete
                        v-if="day"
                        v-model="pasteDay"
                        :label="$t('seleziona-su-quali-giorno-vuoi-copiarlo')"
                        :items="listDays"
                        class="pa-4"
                        outlined
                        multiple
                    ></v-autocomplete
                ></v-card-text>
                <v-card-actions>
                    <v-btn color="error" text @click="clearCopyDialog"> {{ $t('annulla') }} </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="copyDay"> {{ $t('copia') }} </v-btn>
                </v-card-actions>

                <v-divider></v-divider> </v-card
        ></v-dialog>
    </v-container>
</template>

<script>
import { max, min } from 'moment'

export default {
    name: 'Opening',
    data() {
        return {
            //opening: null,
            copyDialog: false,
            editing: null,
            day: {},
            pasteDay: [],
            order: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
            op: {
                mon: {
                    moTimeOpen: null,
                    moTimeClose: null,
                    afTimeOpen: null,
                    afTimeClose: null,
                    morningClosed: false,
                    afternoonClosed: false,
                    hourContinued: false
                },
                tue: {
                    moTimeOpen: null,
                    moTimeClose: null,
                    afTimeOpen: null,
                    afTimeClose: null,
                    morningClosed: false,
                    afternoonClosed: false,
                    hourContinued: false
                },
                wed: {
                    moTimeOpen: null,
                    moTimeClose: null,
                    afTimeOpen: null,
                    afTimeClose: null,
                    morningClosed: false,
                    afternoonClosed: false,
                    hourContinued: false
                },
                thu: {
                    moTimeOpen: null,
                    moTimeClose: null,
                    afTimeOpen: null,
                    afTimeClose: null,
                    morningClosed: false,
                    afternoonClosed: false,
                    hourContinued: false
                },
                fri: {
                    moTimeOpen: null,
                    moTimeClose: null,
                    afTimeOpen: null,
                    afTimeClose: null,
                    morningClosed: false,
                    afternoonClosed: false,
                    hourContinued: false
                },
                sat: {
                    moTimeOpen: null,
                    moTimeClose: null,
                    afTimeOpen: null,
                    afTimeClose: null,
                    morningClosed: false,
                    afternoonClosed: false,
                    hourContinued: false
                },
                sun: {
                    moTimeOpen: null,
                    moTimeClose: null,
                    afTimeOpen: null,
                    afTimeClose: null,
                    morningClosed: false,
                    afternoonClosed: false,
                    hourContinued: false
                }
            },
            sortweek: { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5 }
        }
    },

    props: {
        pedit: { type: Boolean, default: false },
        value: { type: Object }
    },

    watch: {
        pedit: function () {
            this.editing = this.pedit
        }
    },
    async created() {
        this.editing = this.pedit
    },

    computed: {
        opening: {
            get() {
                if (this.value) {
                    return this.value
                } else {
                    this.$emit('input', this.op)
                    return this.op
                }
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        listDays() {
            let l = Object.keys(this.opening)
                .filter((z) => z != 'id' && z != 'sat' && z != 'sun')
                .sort((a, b) => this.sortweek[a] - this.sortweek[b])
                .map((x) => {
                    if (this.day && x == this.day) {
                        return { value: x, text: this.$getDayName(x), disabled: true }
                    } else {
                        return { text: this.$getDayName(x), value: x }
                    }
                })
            return l
        }
    },

    methods: {
        allowedMinutes: (m) => m % 30 === 0,
        clearCopyDialog() {
            this.day = {}
            this.pasteDay = []

            this.copyDialog = false
        },

        fullClose(day) {
            if (day.morningClosed && day.afternoonClosed) {
                return true
            } else {
                return false
            }
        },

        copyDay() {
            this.pasteDay.forEach((x) => {
                this.$set(this.opening, x, {
                    moTimeOpen: this.opening[this.day].moTimeOpen,
                    moTimeClose: this.opening[this.day].moTimeClose,
                    afTimeOpen: this.opening[this.day].afTimeOpen,
                    afTimeClose: this.opening[this.day].afTimeClose,
                    morningClosed: this.opening[this.day].morningClosed,
                    afternoonClosed: this.opening[this.day].afternoonClosed,
                    hourContinued: this.opening[this.day].hourContinued
                })
            })
            this.copyDialog = false
        },
        setContinued(day) {
            if (day.hourContinued) {
                day.moTimeClose = '13:00'
                day.afTimeOpen = '13:00'
            } else {
                day.moTimeClose = null
                day.afTimeOpen = null
            }
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        }
    }
}
</script>
<style>
.v-data-table .v-data-table__wrapper {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
.v-input--reverse .v-input__slot {
    flex-direction: row-reverse;
    margin-left: 10px;
}
.border-hour {
    border: 3px solid white;
}
h3 {
    text-transform: uppercase !important;
}
th {
    text-transform: capitalize !important;
}
td {
    text-transform: capitalize !important;
}
</style>
