<template>
    <div>
        <!-------------- menù burger
              ----------------->
        <v-navigation-drawer v-model="$store.state.sidebar" temporary app v-if="$auth.user">
            <v-list-item class="px-2 mt-5">
                <v-list-item-avatar>
                    <v-icon x-large color="primary">account_circle </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                    <router-link :to="`/user/${$auth.utente.agent.id}`">
                        <span class="text-capitalize">{{ $t('ciao-name', { name: $auth.utente.agent.Name }) }}</span>
                    </router-link></v-list-item-title
                >
                <v-btn icon @click="$store.commit('sidebar', !$store.state.sidebar)">
                    <v-icon> arrow_back_ios </v-icon>
                </v-btn>
            </v-list-item>

            <v-divider></v-divider>

            <v-list class="text-uppercase">
                <router-link to="/giro">
                    <v-list-item link>
                        <v-list-item-content>
                            <v-list-item-title class="mt-10 color-menu"> <v-icon class="ml-1 mr-1"> route </v-icon> {{ $tc('giro', 1) }} </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link to="/leads">
                    <v-list-item link>
                        <v-list-item-content>
                            <v-list-item-title class="mt-10 color-menu">
                                <v-icon class="ml-1 mr-1"> apartment </v-icon> {{ $tc('lead', 2) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link to="/map">
                    <v-list-item link>
                        <v-list-item-content>
                            <v-list-item-title class="mt-10 color-menu"> <v-icon class="ml-1 mr-1"> route </v-icon> {{ $t('mappa') }} </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link to="/users" v-if="$permission($auth.utente.role.name, 'AgentPage')">
                    <v-list-item link>
                        <v-list-item-content>
                            <v-list-item-title class="mt-10 color-menu"> <v-icon class="ml-1 mr-1"> group </v-icon> {{ $tc('agente', 2) }} </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link to="/zones" v-if="$permission($auth.utente.role.name, 'ZonePage')">
                    <v-list-item link>
                        <v-list-item-content>
                            <v-list-item-title class="mt-10 color-menu"> <v-icon class="ml-1 mr-1"> pin_drop </v-icon> {{ $tc('zona', 2) }} </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link to="/admin" v-if="$permission($auth.utente.role.name, 'AdminPage')">
                    <v-list-item link>
                        <v-list-item-content>
                            <v-list-item-title class="mt-10 color-menu"> <v-icon class="ml-1 mr-1"> engineering </v-icon> admin </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list>

            <template>
                <div class="pa-2 mt-16">
                    <v-btn color="primary" @click="signout" block> Logout </v-btn>
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar class="mr-8 ml-8 mt-3 mb-3" color="primary" rounded absolute>
            <v-container class="bg-surface-variant d-none d-md-flex">
                <v-row class="">
                    <!--------------  LOGO
              ----------------->
                    <v-col class="d-flex justify-start mt-1" cols="3">
                        <img class="logo" src="https://gruppodam.it/files/logo.jpg" width="110px" height="45px" />
                    </v-col>
                    <!-------------- MENU
              ----------------->
                    <v-col class="d-flex justify-center align-self-center" cols="6">
                        <router-link to="/giro">
                            <v-btn x-large color="#FAFAFA" text>{{ $tc('giro', 1) }}</v-btn>
                        </router-link>
                        <router-link to="/leads">
                            <v-btn x-large color="#FAFAFA" text> {{ $tc('lead', 2) }} </v-btn>
                        </router-link>
                        <router-link to="/map">
                            <v-btn x-large color="#FAFAFA" text> {{ $t('mappa') }}</v-btn>
                        </router-link>
                        <router-link to="/users" v-if="$permission($auth.utente.role.name, 'AgentPage')">
                            <v-btn x-large color="#FAFAFA" text>{{ $tc('agente', 2) }}</v-btn>
                        </router-link>
                        <router-link to="/zones" v-if="$permission($auth.utente.role.name, 'ZonePage')">
                            <v-btn x-large color="#FAFAFA" text>{{ $tc('zona', 2) }}</v-btn>
                        </router-link>
                        <!-------------- ADMIN ----------------->

                        <v-menu open-on-hover offset-y>
                            <template v-slot:activator="{ on, attrs }" v-if="$permission($auth.utente.role.name, 'AdminPage')">
                                <v-btn to="/admin" x-large color="#FAFAFA" text v-bind="attrs" v-on="on">admin</v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(menuAdmin, index) in menuAdmin" :key="index">
                                    <v-list-item-title>
                                        <router-link :to="menuAdmin.to">{{ menuAdmin.title }}</router-link></v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>

                    <!-------------- USER ----------------->
                    <v-col class="d-flex justify-end mt-3" cols="3">
                        <v-chip pill link class="transparent rounded-pill">
                            <router-link :to="`/user/${$auth.utente.agent.id}`">
                                <span color="#FFFFFF" class="white--text"
                                    >{{ $t('ciao-name', { name: $auth.utente.agent.Name }) }}<span class="text-capitalize white--text"></span
                                ></span>
                            </router-link>
                        </v-chip>
                        <v-chip class="transparent">
                            <v-btn color="white" icon @click="signout">
                                <v-icon>logout</v-icon>
                            </v-btn>
                        </v-chip>
                    </v-col>
                </v-row>
            </v-container>
            <!--------------  modalità telefono
          ----------------->
            <v-row class="d-flex d-md-none align-content-center">
                <!--------------  icona burger
          ----------------->
                <v-col cols="6">
                    <v-app-bar-nav-icon @click="$store.commit('sidebar', !$store.state.sidebar)" color="#FFFFFF"></v-app-bar-nav-icon>
                </v-col>
                <!--------------  LOGO
          ----------------->
                <v-col class="mt-1" cols="6" style="text-align: end">
                    <img class="logo" src="https://gruppodam.it/files/logo.jpg" width="100px" />
                </v-col>

                <v-spacer></v-spacer>
            </v-row>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    name: 'DHeader',
    props: {
        notifications: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data: () => ({
        drawer: false,
        group: null,
        links: ['Dashboard', 'Messages', 'Profile', 'Updates'],
        menuAdmin: [
            { title: 'MARKETING', to: '/admin/marketing' },
            { title: 'REPORT PROVINCE', to: '/admin/repprovince' },
            { title: 'PULISCI DATABASE', to: '/admin/cleardb' },
            { title: 'ACTION LOGS', to: '/admin/actionlog' }
        ]
    }),
    watch: {
        group() {
            this.drawer = false
        }
    },
    methods: {
        async signout() {
            //this.$store.commit('appLoading', true)

            try {
                await this.$auth.signout()
                this.$router.go()
            } catch (err) {
                alert(err.message || err.name)
            } finally {
                //this.$store.commit('appLoading', false)
            }
        },
        closeSidebar() {
            this.$store.commit('toggleSidebar', false)
        }
    }
}
</script>
<style>
.router-link-active {
    opacity: 0.5;
    pointer-events: none;
}

.color-menu {
    color: #1976d2;
}
</style>
