import moment from 'moment'

export default {
    data() {
        return {
            //msg: 'Hello World'
        }
    },
    created: function () {
        //console.log('Printing from the Mixin')
    },
    methods: {
        $rules(rule) {
            const rules = {
                maxLength250: [(v) => !v || (v && v.length <= 250) || this.$t('lunghezza-massima-250-caratteri')],
                Required: [(v) => !!v || this.$t('questo-campo-e-richiesto')],
                MinLength6: [(v) => !v || (v && v.length >= 6) || this.$t('lunghezza-minima-6-caratteri')],
                RequiredObj: [(v) => !!v?.id || this.$t('questo-campo-e-richiesto')],
                RequiredArr: [(v) => !!v.length || this.$t('questo-campo-e-richiesto')],
                email: [(v) => !v || /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,},?)+$/i.test(v) || this.$t('inserisci-unemail-corretta')],
                onlyNumbers: [(v) => !v || /^\d+$/.test(v) || this.$t('questo-campo-deve-contere-solo-da-numeri')]
            }
            return rules[rule]
        },
        $getDayName(value = 'all') {
            const days = [
                { text: this.$t('lunedi'), value: 'mon' },
                { text: this.$t('martedi'), value: 'tue' },
                { text: this.$t('mercoledi'), value: 'wed' },
                { text: this.$t('giovedi'), value: 'thu' },
                { text: this.$t('venerdi'), value: 'fri' },
                { text: this.$t('sabato'), value: 'sat' },
                { text: this.$t('domenica'), value: 'sun' }
            ]
            if (value == 'all') {
                return days.filter(function (obj) {
                    return obj.value !== 'sat' && obj.value !== 'sun'
                })
            } else {
                return days.find((x) => x.value == value).text || this.$t('non-trovato')
            }
        },
        $permission(role, permission) {
            const ruoli = {
                Agente: ['deleteLead'],
                Concessionario: ['AgentPage', 'ZonePage', 'deleteLead', 'groupLeadTable'],
                Ufficio: [
                    'AgentPage',
                    'ZonePage',
                    'LeadToCheckPage',
                    'deleteLead',
                    'deleteLeadToCheck',
                    'deleteUser',
                    'deleteZone',
                    'editUsers',
                    'editZone',
                    'blockUser',
                    'setRole',
                    'setZone',
                    'filterProvince',
                    'filterCity',
                    'FullLeadTable'
                ],
                Super: [
                    'AgentPage',
                    'ZonePage',
                    'LeadToCheckPage',
                    'importcsv',
                    'deleteLead',
                    'deleteLeadToCheck',
                    'deleteUser',
                    'deleteZone',
                    'editUsers',
                    'editZone',
                    'blockUser',
                    'setRole',
                    'setSuperRole',
                    'setZone',
                    'filterProvince',
                    'filterCity',
                    'FullLeadTable',
                    'FullUserTable',
                    'AdminPage',
                    'restoreLead'
                ]
            }
            return ruoli[role].includes(permission)
        },

        $opening2DB(opening) {
            let op = JSON.parse(JSON.stringify(opening))
            Object.keys(op).forEach((key) => {
                if (op[key].moTimeOpen) {
                    op[key].moTimeOpen = moment(op[key].moTimeOpen, 'HH:mm').format('HH:mm:ss.SSS')
                }
                if (op[key].moTimeClose) {
                    op[key].moTimeClose = moment(op[key].moTimeClose, 'HH:mm').format('HH:mm:ss.SSS')
                }
                if (op[key].afTimeOpen) {
                    op[key].afTimeOpen = moment(op[key].afTimeOpen, 'HH:mm').format('HH:mm:ss.SSS')
                }
                if (op[key].afTimeClose) {
                    op[key].afTimeClose = moment(op[key].afTimeClose, 'HH:mm').format('HH:mm:ss.SSS')
                }
            })
            return op
        },

        $opening2UI(opening) {
            let op = JSON.parse(JSON.stringify(opening))
            Object.keys(op).forEach((key) => {
                if (op[key].moTimeOpen) {
                    op[key].moTimeOpen = moment(op[key].moTimeOpen, 'HH:mm:ss.SSS').format('HH:mm')
                }
                if (op[key].moTimeClose) {
                    op[key].moTimeClose = moment(op[key].moTimeClose, 'HH:mm:ss.SSS').format('HH:mm')
                }
                if (op[key].afTimeOpen) {
                    op[key].afTimeOpen = moment(op[key].afTimeOpen, 'HH:mm:ss.SSS').format('HH:mm')
                }
                if (op[key].afTimeClose) {
                    op[key].afTimeClose = moment(op[key].afTimeClose, 'HH:mm:ss.SSS').format('HH:mm')
                }
                Object.assign(op[key], { ...op[key], hourContinued: this.$isContinued(op[key]) })
            })
            return op
        },
        $isContinued(day) {
            if (!day.morningClosed && !day.afternoonClosed && day.moTimeClose == day.afTimeOpen && day.moTimeClose) {
                return true
            } else {
                return false
            }
        },

        $hasOpening(opening) {
            let hasTime = false
            if (opening) {
                for (const [k, day] of Object.entries(opening)) {
                    if (day.morningClosed || day.afternoonClosed || day.moTimeOpen || day.moTimeClose || day.afTimeOpen || day.afTimeClose) {
                        hasTime = true
                        break
                    }
                }
            }
            return hasTime
        },

        $getMapUrl(item) {
            return (
                'https://www.google.it/maps/search/' +
                item.attributes.Name +
                ' ' +
                item.attributes.Address +
                ' ' +
                item.attributes.Province.data.attributes.Name +
                ' ' +
                item.attributes.City.data.attributes.Name +
                ' ' +
                item.attributes.CAP
            )
        },

        $goCoordinate(coordinate) {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${coordinate.lat},${coordinate.lng}`)
        },

        $capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        $getObjValue(o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
            s = s.replace(/^\./, '') // strip a leading dot
            var a = s.split('.')
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i]
                if (o && k in o) {
                    o = o[k]
                } else {
                    return
                }
            }
            return o
        },
        $groupItemBy(array, property) {
            var hash = {},
                props = property.split('.')
            for (var i = 0; i < array.length; i++) {
                var key = props.reduce(function (acc, prop) {
                    return acc && acc[prop]
                }, array[i])
                if (!hash[key]) hash[key] = []
                hash[key].push(array[i])
            }
            return hash
        }
    }
}
