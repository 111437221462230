<template>
    <v-container>
        <!-------------- title ----------------->
        <v-row class="text-center">
            <v-col cols="12">
                <h1 class="display-2 mt-9 pt-15">{{ $t('sezione', { page: this.$tc('lead-da-controllare', 2) }) }}</h1>
            </v-col>
            <v-col cols="12">
                <v-card class="d-flex justify-center mb-6" flat>
                    <!-------------- new agente
                ----------------->
                    <v-row class="mt-5">
                        <!-------------- delete all lead  
                        <v-col cols="2" sm="4" v-if="$auth.utente.role.name == 'Super'">
                            <div>
                                <v-btn rounded color="error" @click="deleteAll"> CANCELLA TUTTI </v-btn>
                            </div>
                        </v-col>
                        ----------------->

                        <!-------------- search ----------------->
                        <v-col cols="10" sm="10">
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                :label="$t('ricerca-nome')"
                                single-line
                                hide-details
                                class="pa-6"
                                clearable
                            ></v-text-field>
                        </v-col>
                        <!-------------- filter                    ----------------->

                        <v-col cols="2" sm="2">
                            <div @click="modalFilter = !modalFilter">
                                <v-btn large rounded color="success" class="d-none d-md-flex mt-5">
                                    <v-icon class="pa-2">filter_alt</v-icon> {{ $t('imposta-filtri') }}
                                </v-btn>
                                <v-icon color="success" class="d-flex d-md-none mt-5" x-large>filter_alt</v-icon>
                            </div>

                            <div class="text-center">
                                <v-bottom-sheet v-model="modalFilter">
                                    <v-sheet class="text-center" height="auto">
                                        <!-------------- reset filter ----------------->
                                        <v-container cols="12">
                                            <v-row class="d-flex justify-center">
                                                <!-- Close button -->
                                                <v-col cols="3" class="d-flex justify-center" @click="modalFilter = !modalFilter">
                                                    <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $t('chiudi') }}</v-btn>
                                                    <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                                                </v-col>

                                                <!-- Reset filters button -->
                                                <v-col cols="6">
                                                    <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                                    <h1 class="mt-4">{{ $tc('filtro', 2) }}</h1>
                                                </v-col>

                                                <!-- Apply button -->
                                                <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                                    <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $t('applica') }}</v-btn>
                                                    <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>

                                        <div class="pa-6">
                                            <v-row>
                                                <v-col cols="12 " sm="6">
                                                    <v-text-field class="" v-model="provFilter" :label="$tc('provincia', 1)" solo-inverted flat></v-text-field>
                                                </v-col>
                                                <v-col cols="12 " sm="6">
                                                    <v-text-field class="" v-model="citFilter" :label="$tc('citta', 1)" solo-inverted flat></v-text-field>
                                                </v-col>
                                                <v-col cols="12 " sm="6">
                                                    <v-text-field v-model="capFilter" :label="$tc('cap', 1)" solo-inverted flat></v-text-field>
                                                </v-col>
                                                <v-col cols="12 " sm="6">
                                                    <v-autocomplete
                                                        :label="$tc('categoria', 1)"
                                                        item-text="name"
                                                        item-value="value"
                                                        multiple
                                                        hide-no-data
                                                        v-model="selectCat"
                                                        :items="categorie"
                                                        solo-inverted
                                                        flat
                                                        chips
                                                        deletable-chips
                                                        clearable
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-sheet>
                                </v-bottom-sheet>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <!------------- GRIGLIE ----------------->
        <v-card class="marginScroll">
            <v-data-table
                :headers="headers"
                :items="leads"
                :options.sync="options"
                :server-items-length="totalLeads"
                :loading="loading"
                :search="search"
                class="pt-6 elevation-6 text-left"
                :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 1000] }"
                show-select
                v-model="selectedLeads"
                @update:options="updateUrl"
                :mobile-breakpoint="1270"
            >
                <!-- pulsante azioni -->

                <template v-slot:top>
                    <v-menu transition="slide-y-transition" bottom v-if="selectedLeads.length" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="ml-6 mt-n2 mb-4" v-bind="attrs" v-on="on"> {{ $tc('azione', 2) }} </v-btn>
                        </template>
                        <v-list class="text-uppercase">
                            <v-list-item @click="showDeleteDialogSelect" v-if="$permission($auth.utente.role.name, 'deleteLeadToCheck')">
                                <v-list-item-title class="ma-4"
                                    ><v-icon color="black" class="mb-1" left> delete </v-icon> {{ $tc('elimina', 1) }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <!-- Aggiunta colonna "azione" -->

                <template #[`item.id`]="{ value }">
                    <v-container class="d-flex flex-row" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
                        <router-link :to="`/LeadToCheck/${value}/edit`">
                            <v-icon class="pt-3 d-inline-flex">edit</v-icon>
                        </router-link>
                        <router-link :to="`/LeadToCheck/${value}`">
                            <v-icon class="pa-3 d-inline-flex">visibility</v-icon>
                        </router-link>
                        <v-icon class="d-inline-flex" @click="showDeleteDialog(value)">cancel</v-icon>
                    </v-container>
                </template>

                <!-- Aggiunta colonna "ORARIO" -->
                <template #[`item.attributes.Opening`]="{ value }">
                    <v-btn :disabled="!$hasOpening(value)" color="primary" @click="showAllOpen(value)">{{ $tc('orario', 1) }} </v-btn>
                </template>
            </v-data-table>
            <v-dialog v-model="showAllOpenDialog" max-width="500">
                <v-card>
                    <v-card-title class="headline justify-center">{{ $tc('orario', 2) }}</v-card-title>
                    <v-card-text>
                        <Opening v-model="selectedOpening" />
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="primary" @click="showAllOpenDialog = false">{{ $tc('chiudi', 1) }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- dialog sicuro di voler cancellare -->

            <v-dialog v-model="showDeleteConfirmation" max-width="500">
                <v-card outlined>
                    <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $tc('confermi-', 1) }} </v-card-title>

                    <v-card-text class="mt-6, text-center mt-6"> {{ deleteDialogMsg }}</v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn text @click="cancelDelete">{{ $tc('annulla', 1) }}</v-btn>
                        <v-spacer></v-spacer>

                        <v-btn color="red" text @click="deleteL(deleteDialogCall)">{{ $tc('elimina', 1) }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
import Opening from '@/components/Opening.vue'

export default {
    name: 'LeadsToCheck',
    components: {
        Opening: Opening
    },
    data() {
        return {
            leads: [],
            totalLeads: 0,
            loading: true,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            search: '',
            timeoutQuery: null,
            showAllOpenDialog: false,
            selectedOpening: [],
            showDeleteConfirmation: false,
            deleteDialogMsg: '',
            deleteDialogCall: null,
            leadToDelete: null,
            modalFilter: false,
            provFilter: null,
            citFilter: null,
            capFilter: null,
            selectedLeads: [],
            changinurl: false,

            headers: [
                {
                    text: this.$tc('azione', 1),
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    field: 'id'
                },
                { text: this.$tc('nome-azienda', 1), value: 'attributes.Name', field: 'Name' },
                {
                    text: this.$tc('indirizzo', 1),
                    value: 'attributes.Address',
                    field: 'Address'
                },
                {
                    text: this.$tc('citta', 1),
                    value: 'attributes.City',
                    field: 'City'
                },
                {
                    text: this.$tc('cap', 1),
                    value: 'attributes.CAP',
                    field: 'CAP'
                },
                {
                    text: this.$tc('provincia', 1),
                    value: 'attributes.Province',
                    field: 'Province'
                },
                {
                    text: this.$tc('telefono', 1),
                    value: 'attributes.Phone',
                    field: 'Phone'
                },
                {
                    text: this.$tc('orario', 1),
                    value: 'attributes.Opening',
                    field: 'Opening',
                    sortable: false
                }
            ],
            categorie: [],
            selectCat: []
        }
    },
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'LeadToCheckPage')) {
            this.$router.push('/')
        }
        this.changinurl = true
        if (this.$route.query.page) {
            this.options.page = parseInt(this.$route.query.page)
        }
        if (this.$route.query.items) {
            this.options.itemsPerPage = parseInt(this.$route.query.items)
        }
        if (this.$route.query.sortBy) {
            this.options.sortBy[0] = this.$route.query.sortBy
            if (this.$route.query.sortDesc) {
                this.options.sortDesc[0] = this.$route.query.sortDesc
            }
        }
        if (this.$route.query.cap) {
            this.selectCaps = this.$route.query.cap.split(',')
        }
        if (this.$route.query.cat) {
            this.selectCat = this.$route.query.cat.split(',').map((x) => {
                if (x == 'empty') {
                    return ''
                } else {
                    return x
                }
            })
        }
        if (this.$route.query.search) {
            this.search = decodeURIComponent(this.$route.query.search)
        }
        if (this.$route.query.provincia) {
            this.provFilter = decodeURIComponent(this.$route.query.provincia)
        }
        if (this.$route.query.citta) {
            this.citFilter = decodeURIComponent(this.$route.query.citta)
        }
        if (this.$route.query.cap) {
            this.capFilter = decodeURIComponent(this.$route.query.cap)
        }
        await this.getCategoriesFromApi()
        await this.getDataFromApi()
        this.changinurl = false
    },
    watch: {
        search: {
            handler() {
                if (!this.search || this.search.length == 0) {
                    this.setFilter()
                    return
                }
                if (this.search?.length < 3) {
                    return
                }
                if (this.timeoutQuery) {
                    clearTimeout(this.timeoutQuery)
                }
                this.timeoutQuery = setTimeout(() => {
                    this.setFilter()
                }, 300)
            }
        }
    },
    methods: {
        clearFilters() {
            // Reset all filter
            this.provFilter = null
            this.citFilter = null
            this.capFilter = null
            this.selectCat = []
        },
        updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true
            if (this.options.page > 1) {
                if (this.$route.query.page != this.options.page) {
                    query.page = this.options.page
                }
            } else {
                delete query.page
            }
            if (this.options.itemsPerPage > 10) {
                if (this.$route.query.items != this.options.itemsPerPage) {
                    query.items = this.options.itemsPerPage
                }
            } else {
                delete query.items
            }
            if (this.options.sortBy.length) {
                if (this.$route.query.sortBy != this.options.sortBy) {
                    query.sortBy = this.options.sortBy
                    if (this.options.sortDesc.length && this.$route.query.sortDesc != this.options.sortDesc) {
                        query.sortDesc = this.options.sortDesc
                    }
                }
            } else {
                delete query.sortBy
                delete query.sortDesc
            }
            if (this.search) {
                if (this.$route.query.search != this.search) {
                    query.search = encodeURIComponent(this.search)
                }
            } else {
                delete query.search
            }
            if (this.provFilter) {
                if (this.$route.query.provincia != this.provFilter) {
                    query.provincia = encodeURIComponent(this.provFilter)
                }
            } else {
                delete query.provincia
            }
            if (this.citFilter) {
                if (this.$route.query.citta != this.citFilter) {
                    query.citta = encodeURIComponent(this.citFilter)
                }
            } else {
                delete query.citta
            }
            if (this.capFilter) {
                if (this.$route.query.cap != this.capFilter) {
                    query.cap = encodeURIComponent(this.capFilter)
                }
            } else {
                delete query.cap
            }
            if (this.selectCat.length) {
                if (
                    this.$route.query.cat !=
                    this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                ) {
                    query.cat = this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                }
            } else {
                delete query.cat
            }

            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})
            this.getDataFromApi()
            this.changinurl = false
        },
        setFilter() {
            this.options.page = 1
            this.updateUrl()
            this.modalFilter = false
        },
        showDeleteDialog(leadId) {
            this.leadToDelete = leadId
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-il-lead')
            this.deleteDialogCall = true
            this.showDeleteConfirmation = true
        },
        showDeleteDialogSelect() {
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-i-lead-selezionati')
            this.deleteDialogCall = false
            this.showDeleteConfirmation = true
        },
        deleteL(type = false) {
            if (type) {
                this.deleteLead()
            } else {
                this.deleteSelect()
            }
        },
        async deleteLead() {
            try {
                const lead = await this.$http.put(`leadToChecks/${this.leadToDelete}`, {
                    data: { disabled: true }
                })

                await this.getDataFromApi()

                this.showDeleteConfirmation = false
                this.leadToDelete = null
            } catch (error) {
                console.error(error)

                this.$notify({
                    group: 'notifica',
                    title: this.$tc('errore', 1),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-del-lead'),
                    type: 'error'
                })

                this.showDeleteConfirmation = false
                this.leadToDelete = null
            }
        },

        cancelDelete() {
            this.showDeleteConfirmation = false
            this.leadToDelete = null
            this.deleteDialogMsg = ''
            this.deleteDialogCall = false
        },
        async getCategoriesFromApi() {
            const p = await this.$http.get(`categories`, {
                'pagination[pageSize]': 200,
                'sort': 'name'
            })
            if (p.data.length) {
                this.categorie = p.data.map((x) => {
                    return { id: x.id, value: x.attributes.name, name: x.attributes.name }
                })
                this.categorie.push({ id: null, value: '', name: 'Senza Categoria' })
            }
        },

        async getDataFromApi() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            let filters = {}

            filters[`filters[$and][0][disabled][$eq]`] = false
            let cc = 1
            if (this.search) {
                filters[`filters[$and][${cc}][Name][$containsi]`] = this.search
                cc++
            }
            if (this.provFilter) {
                filters[`filters[$and][${cc}][Province][$containsi]`] = this.provFilter
                cc++
            }
            if (this.citFilter) {
                filters[`filters[$and][${cc}][City][$containsi]`] = this.citFilter
                cc++
            }
            if (this.capFilter) {
                filters[`filters[$and][${cc}][CAP][$containsi]`] = this.capFilter
                cc++
            }
            if (this.selectCat?.length) {
                let c = 0
                this.selectCat.forEach((x) => {
                    if (x == '') {
                        filters[`filters[$and][${cc}][$or][${c}][Category][$null]`] = true
                        c++
                    }
                    filters[`filters[$and][${cc}][$or][${c}][Category][$eq]`] = x
                    c++
                })
                cc++
            }
            let sort = 'Name'
            if (sortBy.length) {
                const el = this.headers.find((x) => x.value == sortBy[0])
                sort = el.field.concat(sortDesc[0] ? ':desc' : '')
            }
            const docs = await this.$http.get('leadToChecks', {
                'pagination[pageSize]': itemsPerPage,
                'pagination[page]': page,
                'populate': ['Opening', 'Opening.mon', 'Opening.tue', 'Opening.wed', 'Opening.thu', 'Opening.fri', 'Opening.sat', 'Opening.sun'],
                'sort': sort,
                ...filters
            })

            this.leads = docs.data || docs

            this.totalLeads = docs.meta.pagination.total ? docs.meta.pagination.total : 0
            this.loading = false
        },
        showAllOpen(value) {
            this.selectedOpening = this.$opening2UI(value)

            this.showAllOpenDialog = true
        },
        /*
        async deleteAll() {
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`leadToChecks`, { 'fields': ['Name'], 'pagination[pageSize]': 200, 'pagination[page]': pa })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            for (const x of ci) {
                let a = await this.$http.delete(`leadToChecks/${x.id}`)
            }
            this.getDataFromApi()
        },*/
        async deleteSelect() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLead')) {
                return
            }

            try {
                for (const lead of this.selectedLeads) {
                    await this.$http.put(`leadToChecks/${lead.id}`, {
                        data: { disabled: true }
                    })
                }

                this.getDataFromApi()
                this.selectedLeads = []
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$tc('successo', 1),
                    text: this.$t('leads-selezionati-cancellati-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.error(error)
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$tc('errore', 1),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-dei-leads-selezionati'),
                    type: 'error'
                })
            }
        }
    }
}
</script>
