<template>
    <v-container>
        <loading v-model="loading" />
        <v-row class="text-center">
            <!-------------- title ----------------->
            <v-row :class="{ 'mt-15': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10 pb-5': $vuetify.breakpoint.mdAndUp }">
                <v-col cols="12" sm="6">
                    <h1>
                        {{ $t('sezione', { page: this.$tc('report', 1) + ' ' + this.$tc('provincia', 2) }) }}
                    </h1>
                </v-col>

                <!-------------- esporta  ----------------->
                <v-col cols="12" sm="6" class="d-flex justify-center">
                    <div class="text-center" @click="exportReport" v-show="report.length">
                        <v-btn rounded color="orange" class="d-none d-md-flex" x-large
                            ><v-icon class="pa-2">download</v-icon> {{ $t('esporta', { page: this.$tc('report', 1) }) }}
                        </v-btn>
                        <v-icon color="orange" class="pointer d-flex d-md-none" x-large>download</v-icon>
                    </div></v-col
                >
            </v-row>
        </v-row>
        <v-row class="text-center">
            <template v-if="report.length">
                <v-col cols="12" sm="6" v-for="r in report" :key="'p' + r.name">
                    <v-card class="mx-auto pa-10 text-left" elevation="10">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <b class="text-uppercase"> {{ $tc('provincia', 1) }}</b>
                                <p class="text-capitalize mb-0">{{ r.name }}</p>
                                <template v-for="d in r.dett">
                                    <b :key="r.name + d.name" class="text-uppercase">{{ d.name || 'vuoto' }}:</b> {{ d.number }} ||
                                </template>
                                <b class="text-uppercase">Totale Provincia:</b> {{ r.all }}
                            </v-col>
                            <v-col cols="12" sm="6">
                                <template v-if="r.cities.length">
                                    <div v-for="c in r.cities" :key="'c' + c.name" class="mb-1">
                                        <b class="text-uppercase"> {{ $tc('citta', 1) }}</b>
                                        <p class="text-capitalize mb-0">{{ c.name }}</p>
                                        <template v-for="d in c.dett">
                                            <b :key="c.name + d.name" class="text-uppercase">{{ d.name || 'vuoto' }}:</b> {{ d.number }} ||
                                        </template>
                                        <b class="text-uppercase">Totale Città:</b> {{ c.all }}
                                    </div>
                                </template>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import loading from '@/components/loading.vue'
export default {
    name: 'RepProvince',
    components: { loading },
    data() {
        return {
            loading: true,
            report: []
        }
    },
    watch: {},
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'AdminPage')) {
            this.$router.push('/')
        }
        await this.genReport()
    },
    methods: {
        async itemConvert() {
            if (typeof this.report === 'object' && Object.keys(this.report).length > 0) {
                const reportArray = Object.values(this.report)

                this.report = reportArray
            }
        },
        async exportReport() {
            try {
                let txt = ''
                for (const p of this.report) {
                    txt += `${this.$tc('provincia', 1)} ${p.name}: Totale: ${p.all} ${p.dett
                        .map((x) => `- ${x.name == '' ? 'vuoto' : x.name} : ${x.number} `)
                        .join('')}\n`
                    for (const c of p.cities) {
                        txt += `${this.$tc('citta', 1)} ${c.name}: Totale: ${c.all} ${c.dett
                            .map((x) => `- ${x.name == '' ? 'vuoto' : x.name} : ${x.number} `)
                            .join('')}\n`
                    }
                }

                const anchor = document.createElement('a')
                anchor.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt)
                anchor.target = '_blank'
                anchor.download = `reportProvince-${this.$moment().format('YYMMDD')}.txt`
                anchor.click()
            } catch (error) {
                /*this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-durante-la-richiesta-di-importazione-dei-leads'),
                    type: 'error'
                })*/
                console.error(error)
            }
        },
        async genReport() {
            try {
                this.loading = true

                this.report = await this.$http.get(`rprovince`, {})
            } catch (err) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-generazione-report'),
                    type: 'error'
                })
                console.log(err)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
