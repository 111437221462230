<template>
    <v-container>
        <v-row class="d-flex">
            <v-col cols="6" class="align-center"
                ><h1>{{ $t('reminders') }}</h1></v-col
            >
            <v-col cols="6" class="d-flex justify-end"
                ><div @click="modalFilter = !modalFilter">
                    <v-btn large rounded color="success" class="d-none d-md-flex"> <v-icon class="pa-2">filter_alt</v-icon> {{ $t('imposta-filtri') }} </v-btn>
                    <v-icon color="success" class="d-flex d-md-none" x-large>filter_alt</v-icon>
                </div></v-col
            >
        </v-row>

        <v-data-table
            :headers="headers"
            :items="leads"
            :options.sync="options"
            :loading="loading"
            :search="search"
            class="pt-6 scrollabile"
            :item-class="itemRowBackground"
            @update:options="start"
            :mobile-breakpoint="1270"
            :fixed-header="true"
            height="400"
            hide-default-footer
            show-select
            v-model="selectedLeads"
        >
            <!-- pulsante azioni -->

            <template v-slot:top>
                <div class="mr-4">
                    <v-menu transition="slide-y-transition" bottom v-if="selectedLeads.length" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on"> {{ $tc('azione', 2) }} </v-btn>
                        </template>
                        <v-list class="text-uppercase">
                            <v-list-item @click="addTo()">
                                <v-list-item-title class="ma-5 pointer">
                                    <v-icon color="black" class="mb-1" left> add_location_alt </v-icon>
                                    {{ $t('aggiungi-a-', { to: $tc('giro', 1) }) }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="reset()">
                                <v-list-item-title class="ma-5 pointer">
                                    <v-icon color="black" class="mb-1" left> restart_alt </v-icon> {{ $t('reset') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <!-- Aggiunta colonna "azione" -->

            <template #[`item.id`]="{ value }">
                <v-container class="d-flex flex-row pl-0 action-mobile">
                    <v-icon class="pr-3 d-inline-flex d-sm-none" @click="addTo(value)">add_location_alt</v-icon>

                    <v-icon class="pa-1 d-inline-flex" @click="openDetail(value)">visibility</v-icon>

                    <v-icon class="pr-3 d-inline-flex d-sm-none" @click="reset(value)">restart_alt</v-icon>

                    <!--pulsante espandi-->
                    <div class="d-none d-sm-flex">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">expand_more</v-icon>
                            </template>
                            <v-list class="text-uppercase">
                                <v-list-item class="d-flex flex-row" @click="addTo(value)">
                                    <v-btn text color="black">
                                        <v-icon class="pa-3 d-inline-flex">add_location_alt</v-icon>{{ $t('aggiungi-a-', { to: $tc('giro', 1) }) }}</v-btn
                                    >
                                </v-list-item>
                                <v-list-item class="d-flex flex-row" @click="reset(value)">
                                    <v-btn text color="black"> <v-icon class="pa-3 d-inline-flex">restart_alt</v-icon>{{ $t('reset') }}</v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-container>
            </template>

            <!-- Aggiunta colonna "ORARIO" -->
            <template #[`item.attributes.lead.data.attributes.Opening`]="{ value }">
                <v-btn :disabled="!$hasOpening(value)" color="primary" @click="showAllOpen(value)">{{ $tc('orario', 1) }} </v-btn>
            </template>

            <template #[`item.attributes.lead.data.attributes.Province`]="{ value }">
                {{ value?.data?.attributes.Name || '' }}
            </template>
            <template #[`item.attributes.lead.data.attributes.City`]="{ value }">
                {{ value?.data?.attributes.Name || '' }}
            </template>
        </v-data-table>
        <v-dialog v-model="showAllOpenDialog" max-width="500">
            <v-card>
                <v-card-title class="headline justify-center">{{ $tc('orario', 2) }}</v-card-title>
                <v-card-text>
                    <Opening v-model="selectedOpening" />
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="error" @click="showAllOpenDialog = false">{{ $tc('chiudi', 1) }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div class="text-center">
            <v-bottom-sheet v-model="modalFilter">
                <v-sheet class="text-center" height="auto">
                    <!-------------- reset filter ----------------->
                    <v-container cols="12">
                        <v-row class="d-flex justify-center">
                            <!-- Close button -->
                            <v-col cols="3" class="d-flex justify-center" @click="modalFilter = !modalFilter">
                                <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $tc('chiudi', 1) }}</v-btn>
                                <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                            </v-col>

                            <!-- Reset filters button -->
                            <v-col cols="6">
                                <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                <h1 class="mt-4">{{ $tc('filtro', 2) }}</h1>
                            </v-col>

                            <!-- Apply button -->
                            <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $tc('applica', 1) }}</v-btn>
                                <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div class="pa-6">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                    :label="$tc('provincia', 1)"
                                    item-text="name"
                                    :search-input.sync="searchProv"
                                    cache-items
                                    hide-no-data
                                    v-model="selectProv"
                                    :loading="loadingProv"
                                    :items="Province"
                                    return-object
                                    solo-inverted
                                    flat
                                    chips
                                    deletable-chips
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                    :label="$t('citta')"
                                    item-text="name"
                                    :search-input.sync="searchCity"
                                    cache-items
                                    hide-no-data
                                    v-model="selectCity"
                                    :loading="loadingCity"
                                    :items="Cities"
                                    return-object
                                    solo-inverted
                                    flat
                                    chips
                                    deletable-chips
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div class="d-flex flex-row">
                                    <v-text-field v-model="selectCap" :label="$t('cap')" solo-inverted flat type="number"> </v-text-field></div></v-col
                        ></v-row>
                    </div>
                </v-sheet>
            </v-bottom-sheet>
        </div>
    </v-container>
</template>

<script>
import Opening from '@/components/Opening.vue'

export default {
    name: 'reminder',
    components: {
        Opening
    },
    data() {
        return {
            leads: [],
            loading: true,
            options: {
                page: 1,
                itemsPerPage: 200,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            search: '',
            timeoutQuery: null,
            showAllOpenDialog: false,
            selectedOpening: [],
            headers: [
                {
                    text: this.$tc('scadenza', 1),
                    value: 'attributes.expiredate',
                    sortable: false,
                    field: 'expiredate'
                },
                {
                    text: this.$tc('azione', 1),
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    field: 'id'
                },
                { text: this.$tc('nome', 1), value: 'attributes.lead.data.attributes.Name', field: 'Name', sortable: false },
                {
                    text: this.$tc('indirizzo', 1),
                    value: 'attributes.lead.data.attributes.Address',
                    field: 'Address',
                    sortable: false
                },
                {
                    text: this.$tc('citta', 1),
                    value: 'attributes.lead.data.attributes.City',
                    field: 'City.Name',
                    sortable: false
                },
                {
                    text: this.$tc('cap', 1),
                    value: 'attributes.lead.data.attributes.CAP',
                    field: 'CAP',
                    sortable: false
                },
                {
                    text: this.$tc('provincia', 1),
                    value: 'attributes.lead.data.attributes.Province',
                    field: 'Province.Name',
                    sortable: false
                },
                {
                    text: this.$tc('orario', 1),
                    value: 'attributes.lead.data.attributes.Opening',
                    sortable: false,
                    field: 'Opening'
                },
                {
                    text: this.$tc('ultima-visita', 1),
                    value: 'attributes.lastdate',
                    sortable: false,
                    field: 'lastdate'
                },
                {
                    text: this.$tc('giorni-reminder', 1),
                    value: 'attributes.days',
                    sortable: false,
                    field: 'Opening'
                }
            ],
            modalFilter: false,
            searchProv: null,
            selectProv: null,
            loadingProv: false,
            Province: [],
            searchCity: null,
            selectCity: null,
            loadingCity: false,
            Cities: [],
            selectCap: null,
            selectedLeads: []
        }
    },

    props: { refresh: { type: Array } },

    watch: {
        refresh(val) {
            if (!val.length) {
                setTimeout(() => this.getDataFromApi(), 1000)
            }
        },
        searchProv(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterProvince')) {
                val && this.getProvince(val)
            } else {
                val
            }
        },
        searchCity(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterCity')) {
                val && this.getCities(val)
            } else {
                val
            }
        }
    },
    async created() {
        this.start()
    },

    computed: {},

    methods: {
        async start() {
            if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                //recupero zone assegnate
                let prov = new Set()
                let cit = new Set()
                const p = await this.$http.get(`users/me`, { populate: ['agent', 'agent.zones'] })
                if (p.agent.zones) {
                    p.agent.zones.forEach((z) => {
                        z.Rules.forEach((r) => {
                            prov.add(r.prov)
                            r.cities.forEach((c) => {
                                cit.add({ id: c.id, name: c.name })
                            })
                        })
                    })
                }
                this.Province = [...prov]
                this.Cities = [...cit]
            }
            this.getDataFromApi()
        },
        openDetail(id) {
            let l = this.leads.find((x) => x.id == id)
            this.$emit('openDetail', l.attributes.lead.data)
        },
        async addTo(id) {
            if (!id) {
                let l = this.selectedLeads.map((x) => x.attributes.lead.data.id)
                this.$emit('addTo', l)
            } else {
                let l = this.leads.find((x) => x.id == id)
                this.$emit('addTo', l.attributes.lead.data.id)
            }
            await this.getDataFromApi()
            this.$emit('refresh')
        },
        itemRowBackground(item) {
            //da sistemare per scaduti e non scaduti
            if (this.$moment(item.attributes.expiredate) < this.$moment().subtract(1, 'days')) {
                return 'tab-red'
            } else {
                return 'tab-green'
            }
        },
        showAllOpen(value) {
            this.selectedOpening = this.$opening2UI(value)

            this.showAllOpenDialog = true
        },
        async getDataFromApi() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            let filters = {}

            filters[`filters[$and][0][agent][id][$eq]`] = this.$auth.utente.agent.id
            filters[`filters[$and][1][expiredate][$lt]`] = this.$moment().add(7, 'days').format('YYYY-MM-DD')
            let cc = 2
            if (this.selectProv) {
                filters[`filters[$and][${cc}][lead][Province][id][$eq]`] = this.selectProv.id
                cc++
            }
            if (this.selectCity) {
                filters[`filters[$and][${cc}][lead][City][id][$eq]`] = this.selectCity.id
                cc++
            }
            if (this.selectCap) {
                filters[`filters[$and][${cc}][lead][CAP][$containsi]`] = this.selectCap
                cc++
            }

            let sort = 'lastdate'
            if (sortBy.length) {
                const el = this.headers.find((x) => x.value == sortBy[0])
                sort = el.field.concat(sortDesc[0] ? ':desc' : '')
            }
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get('reminders', {
                    'pagination[pageSize]': itemsPerPage,
                    'pagination[page]': page,
                    'populate': [
                        'lead',
                        'lead.Province',
                        'lead.City',
                        'lead.Opening',
                        'lead.Opening.mon',
                        'lead.Opening.tue',
                        'lead.Opening.wed',
                        'lead.Opening.thu',
                        'lead.Opening.fri',
                        'lead.Opening.sat',
                        'lead.Opening.sun'
                    ],
                    'sort': sort,
                    ...filters
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!ci.length) {
                console.log('no data')
                this.leads = []
                this.loading = false
                return
            }
            /*ci.forEach((x) => {
                x.scadenza = this.$moment(x.attributes.lastdate).add(x.attributes.days, 'days').format('YYYY-MM-DD')
            })
            let docs = ci.filter((x) => this.$moment(x.scadenza) < this.$moment().add(7, 'days'))
            docs.sort(function (a, b) {
                return new Date(b.scadenza) - new Date(a.scadenza)
            })*/

            this.leads = ci

            this.loading = false
        },
        async reset(value) {
            let l = []
            if (!value) {
                l = this.selectedLeads.map((x) => x.id)
            } else {
                l = [value]
            }
            for (const x of l) {
                const r = await this.$http.put(`reminders/${x}`, {
                    data: {
                        lastdate: this.$moment().format('YYYY-MM-DD')
                    }
                })
            }

            await this.getDataFromApi()
            this.$emit('refresh')
        },
        /*getMapUrl(id) {
            const item = this.leads.find((x) => x.id == id)
            return this.$getMapUrl(item.attributes.lead.data)
        }*/
        clearFilters() {
            // Reset all filter
            this.searchProv = null
            this.selectProv = null
            this.searchCity = null
            this.selectCity = null
            this.selectCap = null
        },
        setFilter() {
            this.options.page = 1
            this.getDataFromApi()
            this.modalFilter = false
        },
        getProvince(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getProvinceFromApi(val), 300)
        },
        async getProvinceFromApi(val) {
            this.loadingProv = true
            const p = await this.$http.get(`provinces`, {
                'pagination[pageSize]': 200,
                'populate': ['zones'],
                'filters': { Name: { $containsi: val } }
            })
            if (!p.data.length) {
                this.loadingProv = false
                return
            }
            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })

            this.loadingProv = false
        },
        getCities(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getCitiesFromApi(val), 300)
        },
        async getCitiesFromApi(val) {
            this.loadingCity = true
            this.Cities = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name',
                    'filters': { Name: { $containsi: val } }
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!c.data.length) {
                this.loadingCity = false
                return
            }
            this.Cities = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingCity = false
        }
    }
}
</script>
<style>
.v-data-table .v-data-table__wrapper {
    overflow-y: auto !important;
    overflow-x: auto !important;
}
</style>
