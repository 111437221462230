<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-row :class="{ 'mt-10': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                    <!-------------- marketing ----------------->
                    <v-col cols="4" sm="4" class="d-flex justify-center">
                        <router-link :to="`/admin/marketing`">
                            <div class="text-center">
                                <v-btn rounded color="primary" class="d-none d-md-flex" x-large
                                    ><v-icon class="pa-2">groups</v-icon> {{ $t('marketing') }}
                                </v-btn>
                                <v-icon color="primary" class="pointer d-flex d-md-none mt-5" x-large>groups</v-icon>
                            </div>
                        </router-link>
                    </v-col>

                    <!-------------- title ----------------->
                    <v-col cols="4" sm="4" class="d-flex justify-center">
                        <h1>
                            {{ $t('sezione', { page: this.$tc('admin', 1) }) }}
                        </h1>
                    </v-col>
                    <!-------------- province ----------------->
                    <v-col cols="4" sm="4" class="d-flex justify-center">
                        <router-link :to="`/admin/repprovince`">
                            <div class="text-center">
                                <v-btn rounded color="orange" class="d-none d-md-flex" x-large
                                    ><v-icon class="pa-2">download</v-icon> {{ this.$tc('report', 1) + ' ' + this.$tc('provincia', 2) }}
                                </v-btn>
                                <v-icon color="orange" class="pointer d-flex d-md-none mt-5" x-large>download</v-icon>
                            </div>
                        </router-link>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="2" sm="3" class="d-flex justify-center">
                        <router-link :to="`/admin/cleardb`">
                            <div class="text-center">
                                <v-btn rounded color="primary" class="d-none d-md-flex" x-large
                                    ><v-icon class="pa-2">refresh</v-icon> {{ $t('pulisci-db') }}
                                </v-btn>
                                <v-icon color="primary" class="pointer d-flex d-md-none mt-5" x-large>refresh</v-icon>
                            </div>
                        </router-link>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" class="mt-6">
                <v-row :class="{ '': !$vuetify.breakpoint.mdAndUp, 'mt-3': $vuetify.breakpoint.mdAndUp }">
                    <template v-for="s of this.stats">
                        <v-col :cols="12" :sm="6" :key="s.text">
                            <v-card class="mx-auto" max-width="400" :color="s.color" elevation="12">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5">
                                            <v-icon large center class="ma-3 white--text"> {{ s.icon }} </v-icon>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-h5 text-uppercase white--text">{{ s.text }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-card-text class="text-center white--text">
                                    <div class="text-h2">{{ s.value }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Admin',
    components: {},
    data() {
        return {
            loading: true,
            stats: {
                leads: { text: this.$tc('lead', 2), value: null, color: 'primary', icon: 'store' },
                leadstocheck: { text: this.$t('l-da-controllare', { page: this.$tc('lead', 2) }), value: null, color: 'primary', icon: 'warning' },
                agents: { text: this.$tc('agente', 2), value: null, color: 'primary', icon: 'groups' },
                zones: { text: this.$tc('zona', 2), value: null, color: 'primary', icon: 'pin_drop' }
            }
        }
    },
    watch: {},
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'AdminPage')) {
            this.$router.push('/')
        }
        //get number of leads
        let filters = {}
        filters[`filters[$and][0][disabled][$eq]`] = false
        const leads = await this.$http.get('leads', {
            'pagination[pageSize]': 1,
            'pagination[page]': 1,
            ...filters
        })
        this.stats.leads.value = leads.meta.pagination.total || 0
        //get number of leadstocheck
        filters = {}
        filters[`filters[$and][0][disabled][$eq]`] = false
        const leadstocheck = await this.$http.get('leadToChecks', {
            'pagination[pageSize]': 1,
            'pagination[page]': 1,
            ...filters
        })
        this.stats.leadstocheck.value = leadstocheck.meta.pagination.total || 0
        //get number og agents
        filters = {}
        filters[`filters[$and][0][user][role][name][$ne]`] = 'Super'
        filters[`filters[$and][1][user][role][name][$ne]`] = 'Ufficio'
        const agents = await this.$http.get('agents', {
            'pagination[pageSize]': 1,
            'pagination[page]': 1,
            ...filters,
            'populate': ['user', 'user.role']
        })
        this.stats.agents.value = agents.meta.pagination.total || 0
        //get number of zones
        filters = {}
        const zones = await this.$http.get('zones', {
            'pagination[pageSize]': 1,
            'pagination[page]': 1,
            ...filters
        })
        this.stats.zones.value = zones.meta.pagination.total || 0
    },
    methods: {}
}
</script>
<style>
/* Stile CSS personalizzato */
</style>
