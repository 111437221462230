<template>
    <v-container>
        <!--------------  creazione/modifica agente DESKTOP
                       ----------------->
        <div class="">
            <cpLead :pid="id" :pedit="pedit" :pnew="pnew" v-on:error="errorLead"></cpLead>
        </div>
    </v-container>
</template>

<script>
import cpLead from '@/components/cpLead.vue'

export default {
    name: 'Lead',
    components: {
        cpLead
    },
    data() {
        return {
            id: null,
            editing: null,
            newu: null
        }
    },
    props: {
        pedit: { type: Boolean, default: false },
        pnew: { type: Boolean, default: false }
    },

    async created() {
        this.newu = this.pnew

        this.id = parseInt(this.$route.params.id)
    },

    methods: {
        errorLead(err) {
            if (err.type == 'redirect') {
                this.$router.push(err.path)
            }
        }
    }
}
</script>
