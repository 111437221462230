import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { it, es } from 'vuetify/lib/locale'
import facebook from '@/components/social/facebook.vue'
import instagram from '@/components/social/instagram.vue'
import linkedin from '@/components/social/linkedin.vue'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { it, es },
        fallback: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'it',
        current: process.env.VUE_APP_I18N_LOCALE || 'it'
    },
    icons: {
        iconfont: 'md',
        values: {
            facebook: {
                component: facebook
            },
            instagram: {
                component: instagram
            },
            linkedin: {
                component: linkedin
            }
        }
    },
    theme: {
        dark: false
    }
})
