<template>
    <v-container>
        <loading v-model="loading" />
        <!-------------- TITOLO
                  ----------------->
        <v-row>
            <v-col cols="12">
                <h1 class="pt-16 text-center">
                    <span v-show="!editing && !newu">{{ $t('informazioni') }} </span>
                    <span v-show="newu">{{ $t('creazione') }} </span>
                    <span v-show="editing && !newu">{{ $t('modifica') }} </span>
                    {{ $tc('agente', 1) }}
                </h1>
            </v-col>
        </v-row>

        <!--------------  creazione/modifica agente DESTKTOP
                       ----------------->
        <v-card class="overflow-hidden mx-auto" color="primary" dark>
            <v-toolbar color="primary darken-1">
                <a @click="$router.go(-1)">
                    <v-icon class="mr-3 ml-4">arrow_back</v-icon>
                </a>

                <v-spacer></v-spacer>
                <div class="d-none d-sm-flex">
                    <v-switch
                        v-model="User.blocked"
                        inset
                        :label="$t('bloccato')"
                        class="pa-4"
                        color="red"
                        hide-details
                        :readonly="!editing"
                        v-if="$permission($auth.utente.role.name, 'blockUser')"
                    ></v-switch>
                </div>
                <v-btn color="green " fab small @click="Edit(true)" v-show="!editing">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                    color="red "
                    class="ml-2"
                    fab
                    small
                    @click="showDeleteConfirmation = true"
                    v-show="!editing"
                    v-if="$permission($auth.utente.role.name, 'deleteUser')"
                >
                    <v-icon>delete</v-icon>
                </v-btn>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="success" v-show="newu" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                    <v-btn color="success" v-show="editing && !newu" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                    <v-btn color="error" v-show="editing && !newu" @click="Edit(false)" :disabled="loading">
                        {{ $t('annulla') }}
                    </v-btn>
                </v-card-actions>
            </v-toolbar>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="mt-10">
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="Agente.Name"
                            :rules="[$rules('Required'), $rules('maxLength250')].flat()"
                            :label="$t('nome')"
                            :class="{ 'mt-n4 ml-4 mr-4': !$vuetify.breakpoint.smAndUp, 'pa-4': $vuetify.breakpoint.smAndUp }"
                            outlined
                            :readonly="!editing"
                            validate-on-blur
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="User.email"
                            :label="$t('email')"
                            :class="{ 'mt-n4 ml-4 mr-4': !$vuetify.breakpoint.smAndUp, 'pa-4': $vuetify.breakpoint.smAndUp }"
                            :rules="[$rules('Required'), $rules('email'), $rules('maxLength250')].flat()"
                            outlined
                            :readonly="!editing"
                            validate-on-blur
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-text-field
                            v-model="User.password"
                            v-show="editing"
                            :label="$t('password')"
                            :append-icon="showPass ? 'visibility_off' : 'visibility'"
                            :type="showPass ? 'text' : 'password'"
                            @click:append="showPass = !showPass"
                            :class="{ 'mt-n4 ml-4 mr-4': !$vuetify.breakpoint.smAndUp, 'pa-4': $vuetify.breakpoint.smAndUp }"
                            outlined
                            :readonly="!editing"
                            :rules="passRules"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="User.role"
                            :items="roles"
                            :class="{ 'mt-n9 ml-4 mr-4': !$vuetify.breakpoint.smAndUp, 'pa-4': $vuetify.breakpoint.smAndUp }"
                            :label="$t('ruolo')"
                            item-text="name"
                            item-value="id"
                            outlined
                            :readonly="!editing"
                            v-if="$permission($auth.utente.role.name, 'setRole')"
                        ></v-select>
                    </v-col>

                    <!-- Assegnazione zona (display all zones) -->
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            v-model="Agente.zones"
                            :readonly="!editing"
                            :label="$t('assegnazione-zona')"
                            :class="{ 'mt-n4 ml-4 mr-4': !$vuetify.breakpoint.smAndUp, 'pa-4': $vuetify.breakpoint.smAndUp }"
                            outlined
                            multiple
                            :items="allZones"
                            item-text="name"
                            item-value="id"
                            v-if="$permission($auth.utente.role.name, 'setZone')"
                        ></v-autocomplete>
                    </v-col>
                    <div class="d-flex d-sm-none">
                        <v-switch
                            v-model="User.blocked"
                            inset
                            :label="$t('bloccato')"
                            class="ml-10"
                            color="red"
                            hide-details
                            :readonly="!editing"
                            v-if="$permission($auth.utente.role.name, 'blockUser')"
                        ></v-switch>
                    </div>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            :label="$tc('agente', 2)"
                            item-text="name"
                            item-value="id"
                            :search-input.sync="searchAgent"
                            cache-items
                            multiple
                            hide-no-data
                            v-model="Agente.agents"
                            :loading="loadingAgents"
                            :items="agents"
                            return-object
                            outlined
                            chips
                            deletable-chips
                            :clearable="editing"
                            :readonly="!editing"
                            :class="{ 'mt-n4 ml-4 mr-4': !$vuetify.breakpoint.smAndUp, 'pa-4': $vuetify.breakpoint.smAndUp }"
                            v-if="User.role == concessionarioID"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-card-text class="d-flex justify-center"> </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="success" v-show="newu" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                    <v-btn color="success" v-show="editing && !newu" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                    <v-btn color="error" v-show="editing && !newu" @click="Edit(false)" :disabled="loading">
                        {{ $t('annulla') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>

        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> {{ $t('sei-sicuro-di-voler-eliminare-lutente') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="showDeleteConfirmation = false">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="Delete()">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import loading from '@/components/loading.vue'

export default {
    components: {
        loading
    },
    name: 'User',
    data() {
        return {
            Agente: {
                id: null,
                Name: null,
                zones: null,
                agents: []
            },
            User: {
                id: null,
                username: null,
                email: null,
                password: null,
                blocked: false,
                role: null
            },

            allZones: [],
            roles: [],
            searchAgent: null,
            agents: [],
            loadingAgents: false,
            editing: null,
            newu: null,
            loading: false,
            AgenteOriginal: {},
            UserOriginal: {},
            showPass: false,
            valid: true,
            showDeleteConfirmation: false,
            concessionarioID: null
        }
    },
    props: {
        pedit: { type: Boolean, default: false },
        pnew: { type: Boolean, default: false }
    },
    watch: {
        searchAgent(val) {
            val && this.getAgents(val)
        }
    },

    async created() {
        this.newu = this.pnew
        if (this.newu) this.editing = true

        if (this.$route.params.id) {
            if (!this.$permission(this.$auth.utente.role.name, 'editUsers') && this.$auth.utente.agent.id != this.$route.params.id) {
                this.$router.push('/')
                return
            }
            const doc = await this.$http
                .get(`agents/${this.$route.params.id}`, {
                    populate: ['zones', 'user', 'user.role', 'agents']
                })
                .catch((err) => {
                    console.log('errore', err)
                })
            if (!doc) {
                this.$router.push('/')
                return
            }
            if (
                !this.$permission(this.$auth.utente.role.name, 'FullUserTable') &&
                doc.data.attributes.user.data.attributes.role.data.attributes.name == 'Super'
            ) {
                this.$router.push('/')
                return
            }
            if (doc.data.attributes.agents.data.length) {
                this.getAgentsFromApiById(doc.data.attributes.agents.data.map((x) => x.id))
            }
            this.Agente = {
                id: doc.data.id,
                Name: doc.data.attributes.Name,
                zones: doc.data.attributes.zones.data.map((x) => x.id),
                agents: doc.data.attributes.agents.data.map((x) => {
                    return { id: x.id, name: x.attributes.Name }
                })
            }
            this.User = {
                id: doc.data.attributes.user.data.id,

                username: doc.data.attributes.user.data.attributes.username,
                email: doc.data.attributes.user.data.attributes.email,
                password: null,
                blocked: doc.data.attributes.user.data.attributes.blocked,
                role: doc.data.attributes.user.data.attributes.role.data.id
            }
            if (this.pedit) {
                this.Edit(true)
            }
        } else {
            if (!this.$permission(this.$auth.utente.role.name, 'editUsers')) {
                this.$router.push('/')
                return
            }
        }

        const r = await this.$http.get(`roles`)

        this.concessionarioID = r.roles.find((x) => {
            return x.name == 'Concessionario'
        }).id

        let rr = r.roles.filter((x) => {
            return x.name != 'Public'
        })
        if (!this.$permission(this.$auth.utente.role.name, 'setSuperRole')) {
            rr = rr.filter((x) => x.name != 'Super')
        }
        this.roles = rr
        // Get all zones
        this.getZones()
    },
    computed: {
        passRules() {
            if (this.newu) {
                return [this.$rules('Required'), this.$rules('MinLength6')].flat()
            } else {
                return [this.$rules('MinLength6')].flat()
            }
        }
    },

    methods: {
        async getZones() {
            this.allZones = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`zones`, { 'pagination[pageSize]': 200, 'pagination[page]': pa, 'sort': 'Name' })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            this.allZones = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
        },
        getAgents(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getAgentsFromApi(val), 300)
        },
        async getAgentsFromApi(val) {
            this.loadingAgents = true
            this.agents = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`agents`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name',
                    'filters': { Name: { $containsi: val } }
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!c.data.length) {
                this.loadingAgents = false
                return
            }
            this.agents = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingAgents = false
        },
        async getAgentsFromApiById(val) {
            this.loadingAgents = true
            this.agents = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`agents`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name',
                    'filters': { id: { $eq: val } }
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!c.data.length) {
                this.loadingAgents = false
                return
            }
            this.agents = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingAgents = false
        },
        Edit(stato) {
            if (stato) {
                this.UserOriginal = JSON.parse(JSON.stringify(this.User))
                this.AgenteOriginal = JSON.parse(JSON.stringify(this.Agente))
            } else {
                this.User = JSON.parse(JSON.stringify(this.UserOriginal))
                this.Agente = JSON.parse(JSON.stringify(this.AgenteOriginal))
            }

            this.editing = stato
        },
        async Delete() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteUser')) {
                return
            }
            try {
                // Effettua la chiamata HTTP per eliminare il lead
                await this.$http.delete(`users/${this.User.id}`)
                await this.$http.delete(`agents/${this.Agente.id}`)

                // Chiudi il dialogo di conferma ed esegui altre azioni
                this.showDeleteConfirmation = false
                this.$router.push('/users')
            } catch (error) {
                // Gestisci l'errore durante l'eliminazione del lead
                console.error(error)

                // Mostra una notifica di errore
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-della-zona'),
                    type: 'error'
                })
            }
        },
        async Save() {
            if (this.loading) return
            if (!this.$refs.form.validate()) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-form-verificare-i-campi'),
                    type: 'error'
                })
                return
            }
            try {
                this.loading = true
                if (this.concessionarioID != this.User.role) {
                    this.Agente.agents = []
                }
                let usr = {
                    id: this.User.id,
                    username: this.User.email,
                    email: this.User.email,
                    role: this.User.role,
                    blocked: this.User.blocked
                }
                if (this.User.password) {
                    usr.password = this.User.password
                }
                const agent = await this.$http.put(`agents/${this.Agente.id}`, {
                    data: {
                        Name: this.Agente.Name,
                        zones: this.Agente.zones,
                        agents: this.Agente.agents.map((x) => x.id),
                        user: usr
                    }
                })

                //const user = await this.$http.put(`users/${this.User.id}`, usr)
                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                this.editing = false
                this.loading = false
            }
        },
        async Create() {
            let userok = null
            let agentok = null
            if (this.loading) return
            if (!this.$refs.form.validate()) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-form-verificare-i-campi'),
                    type: 'error'
                })
                return
            }
            try {
                this.loading = true
                if (this.concessionarioID != this.User.role) {
                    this.Agente.agents = []
                }
                const user = await this.$http.post(`users`, {
                    username: this.User.email,
                    email: this.User.email,
                    password: this.User.password,
                    blocked: this.User.blocked,
                    role: this.User.role,
                    confirmed: true
                })
                userok = user.id

                const agent = await this.$http.post(`agents`, {
                    data: {
                        Name: this.Agente.Name,
                        user: user.id,
                        zones: this.Agente.zones,
                        agents: this.Agente.agents.map((x) => x.id)
                    }
                })

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
                this.$router.push('/users') // Replace "/sezione-agenti" with the actual route path
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
                if (userok > 0 && !agentok) {
                    const userdel = await this.$http.delete(`users/${userok}`)
                }
            } finally {
                this.loading = false
                // Redirect to Sezione Agenti
            }
        }
    }
}
</script>
