<template>
    <v-overlay v-if="show" :value="show"> <v-progress-circular :size="100" color="white" indeterminate></v-progress-circular> </v-overlay>
</template>

<script>
export default {
    name: 'loading',
    components: {},
    data() {
        return {}
    },

    props: {
        value: { type: Boolean }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>

<style scoped>
.v-progress-circular {
    margin: 5rem;
}
</style>
