import Emitter from './Emitter'

class Auth {
    constructor(appId, http, api = 'auths') {
        this._emitter = new Emitter()
        this._api = api
        this._http = http

        this.appId = appId
        this.token = localStorage.getItem(`d_${this.appId}_aT`) || null
        this.utente = JSON.parse(localStorage.getItem(`d_${this.appId}_user_aT`)) || null
        this.expired = this.token ? Date.now() >= this.user.exp * 1000 : null
        this.interval = null

        if (this.user && this.expired === false) {
            this.watch()
        }
    }

    on(name, cb) {
        const isArr = Array.isArray(name)

        if (isArr) return name.forEach((evt) => this._emitter.on(evt, cb))

        return this._emitter.on(name, cb)
    }

    off(name) {
        const isArr = Array.isArray(name)

        if (isArr) return name.forEach((evt) => this._emitter.off(evt))

        this._emitter.off(name)
    }

    parseToken(token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        )
        return JSON.parse(jsonPayload)
    }

    me() {
        return this._http.get(this._api)
    }

    async signIn(email, pass) {
        const data = await this._http.post(`${this._api}/local`, {
            identifier: email,
            password: pass
        })
        this.token = data.jwt
        localStorage.setItem(`d_${this.appId}_aT`, this.token)
        this.expired = false

        const user = await this._http.get('users/me', { populate: ['agent', 'agent.zones', 'role', 'agent.agents'] }, { Authorization: 'bearer ' + this.token })
        /*this.utente = {
            ...user,
            agent: {
                ...user.agent,
                zones: user.agent.zones.map((x) => {
                    return { id: x.id }
                })
            }
        }*/
        if (user.role.name != 'Ufficio' && user.role.name != 'Super' && user.agent.zones.length == 0) {
            this.signout()
            throw 'nozona'
        }
        this.utente = user
        localStorage.setItem(`d_${this.appId}_user_aT`, JSON.stringify(this.utente))
        this._emitter.emit('signin', this.user)
        return this.token
    }

    async checkUser() {
        try {
            const user = await this._http.get('users/me', { populate: ['agent', 'agent.zones', 'role'] }, { Authorization: 'bearer ' + this.token })
            if (this.utente.id != user.id || this.utente.role.name != user.role.name) {
                this.signout()
                return false
            }
            return true
        } catch (err) {
            if (process.env.NODE_ENV != 'development') {
                this.signout()
                return false
            } else {
                return true
            }
        }
    }

    async forgotPassword(email) {
        const data = await this._http.post(`${this._api}/forgot-password`, {
            email: email
        })

        return data
    }

    async resetPassword(password, confPassword, code) {
        const data = await this._http.post(`${this._api}/reset-password`, {
            password: password,
            passwordConfirmation: confPassword,
            code: code
        })

        return data
    }
    stringEqual(s1, s2) {
        if (s1 !== s2) {
            return false
        }
        return true
    }
    validEmail(email) {
        let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }
    async signout(call = true) {
        if (call) await this._http.post

        localStorage.removeItem(`d_${this.appId}_aT`)
        localStorage.removeItem(`d_${this.appId}_user_aT`)
        this.token = null
        this.utente = null
        this.expired = false
        clearInterval(this.interval)
        this.interval = null

        return true
    }

    watch(cb) {
        this.expired = Date.now() >= this.user.exp * 1000

        const fn = () => {
            this.expired = Date.now() >= this.user.exp * 1000

            this._emitter.emit('watch', {
                expired: this.expired,
                user: this.user
            })

            if (!this.expired) {
                if (typeof cb === 'function') cb(this.user, this.expired)
            } else {
                if (typeof cb === 'function') cb(this.user, this.expired)
                return clearInterval(this.interval)
            }
        }

        if (!this.expired) {
            this.interval = setInterval(fn, 2000)
        }

        this._emitter.emit('watch', {
            expired: this.expired,
            user: this.user
        })

        if (typeof cb === 'function') cb(this.user, this.expired)
    }

    get user() {
        if (!this.token || this.expired === true) {
            this.signout()
            return null
        }
        return this.parseToken(this.token)
    }
}

export default Auth
