import axios from 'axios'
import qs from 'qs'
import Emitter from './Emitter'

axios.defaults.timeout = 180000 // 3m

export default class HTTP {
    constructor(baseUrl, appId, endpoints, requester) {
        this._emitter = new Emitter()

        this.token = localStorage.getItem(`d_${appId}_aT`) || null
        this.appId = appId || null
        this.requester = requester || 'app'
        this.endpoints = endpoints

        this.http = axios.create({
            baseURL: baseUrl,
            withCredentials: true,
            paramsSerializer: (params) => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                    indices: false,
                    allowDots: false,
                    skipNulls: true,
                    format: 'RFC1738'
                })
            },
            validateStatus: (status) => status >= 200 && status < 400
        })

        this.interceptorsRequest()

        return this
    }

    on(name, cb) {
        const isArr = Array.isArray(name)

        if (isArr) return name.forEach((evt) => this._emitter.on(evt, cb))

        return this._emitter.on(name, cb)
    }

    off(name) {
        const isArr = Array.isArray(name)

        if (isArr) return name.forEach((evt) => this._emitter.off(evt))

        this._emitter.off(name)
    }

    // interceptors

    interceptorsRequest() {
        this.http.interceptors.request.use(
            (config) => {
                this.token = localStorage.getItem(`d_${this.appId}_aT`) || null

                config.headers = {
                    ...config.headers
                }

                if (this.token) {
                    config.headers = {
                        ...config.headers,
                        Authorization: 'bearer ' + this.token
                    }
                }

                return config
            },
            (error) => Promise.reject(error)
        )
    }

    // publics

    get(resource, params, headers) {
        return new Promise((resolve, reject) => {
            this.http
                .get(this.getEndpoint(resource), { params: params }, { headers: headers })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err.response?.data))
        })
    }

    post(resource, data, headers) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.getEndpoint(resource), data, { headers: headers })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err.response?.data))
        })
    }

    put(resource, data) {
        return new Promise((resolve, reject) => {
            this.http
                .put(this.getEndpoint(resource), data)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err.response?.data))
        })
    }

    patch(resource, data) {
        return new Promise((resolve, reject) => {
            this.http
                .patch(this.getEndpoint(resource), data)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err.response?.data))
        })
    }

    delete(resource, query) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(this.getEndpoint(resource), { params: query })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err.response?.data))
        })
    }

    // utils

    getEndpoint(api) {
        const endpoint = this.endpoints[api.split('/')[0]]
        const points = api.split('/')
        points.shift()
        if (!points.length) return endpoint
        return endpoint + '/' + points.join('/')
    }
}
