<template>
    <v-container class="mt-14">
        <loading v-model="loading" />

        <v-row>
            <v-col col="6">
                <v-date-picker
                    v-model="date"
                    full-width
                    class="mt-4"
                    first-day-of-week="1"
                    @change="getLeads"
                    :picker-date.sync="pickerDate"
                    :events="events"
                    no-title
                    event-color="green lighten-1"
                >
                    <template v-slot:default>
                        <div v-if="date">
                            <v-btn color="error" @click="date = null">{{ $t('visualizza-reminder-frequenze') }} <v-icon class="pl-3">update</v-icon></v-btn>
                        </div>
                    </template>
                </v-date-picker>
            </v-col>
            <reminder v-if="!date" @openDetail="openDetail" @addTo="addTo" :refresh="ids" @refresh="refresh()"></reminder>

            <v-col cols="12" sm="6" v-if="date">
                <div class="map">
                    <l-map style="width: 100%; height: 100%" :zoom="zoom" :center="center" :options="options" ref="myMap" @ready="mapReady()">
                        <l-control-layers position="topright"></l-control-layers>
                        <l-control position="topleft">
                            <v-btn x-small class="button_location" @click="getCoordinate()"> <v-icon size="22" class="">my_location</v-icon></v-btn>
                        </l-control>
                        <l-tile-layer
                            v-for="tileProvider in tileProviders"
                            :key="tileProvider.name"
                            :name="tileProvider.name"
                            :visible="tileProvider.visible"
                            :url="tileProvider.url"
                            :attribution="tileProvider.attribution"
                            layer-type="base"
                        />
                        <l-marker v-for="(marker, index) in markers" :lat-lng="marker.coordinate" :key="index" :icon="getIcon(marker.opening)">
                            <l-popup v-if="marker.popup">
                                <div>
                                    <h3>{{ marker.popup }}</h3>
                                    {{ marker.optext }}
                                </div>
                                <div class="text-center">
                                    <!--
                                        <v-btn small rounded color="blue" class="" @click.stop="selectMarker(marker)">
                                        <v-icon class="pa-2"> visibility </v-icon>
                                    </v-btn>
                                    -->

                                    <v-btn small rounded color="blue" class="ma-2" @click="$goCoordinate(marker.coordinate)">
                                        <v-icon class="pa-2">near_me</v-icon>
                                    </v-btn>
                                </div>
                            </l-popup>
                        </l-marker>
                    </l-map>
                </div>
            </v-col>
        </v-row>
        <v-card v-if="date && plan.length > 1" class="d-flex d-sm-none mb-5" elevation="5">
            <v-col cols="6" class="text-center"
                ><strong>{{ $t('distanza') }}</strong> <br />
                {{ percorso.distanza }} km
            </v-col>
            <v-col cols="6" class="text-center">
                <strong>{{ $t('tempo') }}</strong> <br />
                {{ percorso.tempo.ore }} {{ $tc('ora', percorso.tempo.ore) }} {{ percorso.tempo.minuti }} {{ $tc('minuto', percorso.tempo.minuti) }}</v-col
            >
        </v-card>
        <div v-if="date && plan.length">
            <v-card class="mx-auto">
                <v-card-title class="white--text primary">
                    <span>{{ $t('lista-giro') }}</span>
                    <v-spacer></v-spacer>
                    <div v-if="plan.length > 1" class="d-none d-sm-flex">
                        <strong class="pr-2">{{ $t('distanza') }} </strong> {{ percorso.distanza }} km
                    </div>
                    <v-spacer></v-spacer>
                    <div v-if="plan.length > 1" class="d-none d-sm-flex">
                        <strong class="pr-2">{{ $t('tempo') }} </strong> {{ percorso.tempo.ore }} {{ $tc('ora', percorso.tempo.ore) }}
                        {{ percorso.tempo.minuti }} {{ $tc('minuto', percorso.tempo.minuti) }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="success" fab small @click="Edit()">
                        <v-icon>{{ draggable ? 'save' : 'edit' }}</v-icon>
                    </v-btn>
                    <v-btn v-show="!draggable" class="ml-2" color="error" fab small @click="showDeleteDialog(plan.map((x) => x.id))">
                        <v-icon>delete</v-icon>
                    </v-btn></v-card-title
                >

                <v-divider></v-divider>
                <draggable :disabled="!draggable" class="v-list" tag="ul" v-model="plan" v-bind="dragOptions" handle=".handle">
                    <transition-group type="transition" name="flip-list">
                        <li class="v-list-item pointer" v-for="element in plan" :key="element.order" @click="selected(element.id)">
                            <v-expansion-panels class="ml-n10 pa-0">
                                <v-expansion-panel>
                                    <v-expansion-panel-header disable-icon-rotate>
                                        {{ element.lead.attributes.Name }}
                                        <template v-slot:actions>
                                            <v-icon v-show="element.note">chat</v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-textarea
                                            counter
                                            :rules="[$rules('maxLength250')].flat()"
                                            auto-grow
                                            outlined
                                            rows="1"
                                            row-height="15"
                                            :label="$t('note')"
                                            v-model="element.note"
                                        ></v-textarea>
                                        <v-btn
                                            color="primary"
                                            elevation="6"
                                            outlined
                                            :disabled="element.note && element.note.length > 256"
                                            @click="saveLead(element.id)"
                                            >{{ $t('salva') }}
                                        </v-btn>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-icon v-show="draggable" class="pa-2 handle">drag_indicator</v-icon>
                            <span v-show="!draggable">
                                <div class="d-flex flex-row">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>expand_more</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item class="d-flex flex-row" @click="selectMarker(element)">
                                                <v-btn text class="pointer"> <v-icon class="pa-2"> visibility </v-icon>{{ $tc('dettaglio', 2) }} </v-btn>
                                            </v-list-item>
                                            <v-list-item class="d-flex flex-row" @click=";(editplan = true), (ids = [element.id])">
                                                <v-btn text class="pointer"> <v-icon class="pa-2">edit_calendar</v-icon>{{ $t('sposta') }} </v-btn>
                                            </v-list-item>
                                            <v-list-item class="d-flex flex-row" @click="showDeleteDialog([element.id])">
                                                <v-btn text class="pointer"> <v-icon class="pa-2">delete</v-icon>{{ $t('cancella') }} </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-btn v-if="element.visited" icon class="btn-disabled"> <v-icon color="green">done_all</v-icon></v-btn>
                                    <v-btn v-else-if="!element.visited" icon @click=";(element.visited = true), saveLead(element.id)">
                                        <v-icon color="grey">check</v-icon></v-btn
                                    >
                                    <v-btn icon @click="$goCoordinate(element.coordinate)">
                                        <v-icon>near_me</v-icon>
                                    </v-btn>
                                </div>
                            </span>
                        </li>
                    </transition-group>
                </draggable>
            </v-card>
        </div>
        <h1 v-if="date && !plan.length" class="text-center justify-center font-weight-black mt-16">
            {{ $t('nessun-lead-selezionato-per-questo-giorno') }}
        </h1>
        <h3 v-if="date && !plan.length" class="text-center justify-center font-weight-black">{{ $t('selezionare-un-altro-giorno') }}</h3>
        <!-------------- info lead ----------------->
        <v-dialog v-model="infoLead" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-items> </v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="closeDetails()"> <v-icon>close</v-icon> {{ $t('chiudi') }} </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>

                <cpLead
                    class="max"
                    v-if="selectedMarker"
                    :pid="selectedMarker"
                    :pbuttons="{ delete: false, backArrow: false, merge: false, addTo: false }"
                    :title="false"
                    v-on:error="errorDetails"
                ></cpLead>
                <span v-show="erroreDetail">{{ erroreDetail }}</span>
            </v-card>
        </v-dialog>

        <!--sei sicuro di voler eliminare-->
        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> {{ deleteDialogMsg }}</v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="cancelDelete">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn color="red" text @click="deleteLead(id)">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <addToPlan v-model="ids" :edit="editplan"></addToPlan>
    </v-container>
</template>

<script>
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet/dist/leaflet.css'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LControlLayers, LControl } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import cpLead from '@/components/cpLead.vue'
import draggable from 'vuedraggable'
import loading from '@/components/loading.vue'
import addToPlan from '@/components/addToPlan.vue'
import reminder from '@/components/reminder.vue'
import axios from 'axios'

//https://leaflet-extras.github.io/leaflet-providers/preview/

/*delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('../public/img/marker/marker_green_2x.png'),
    iconUrl: require('public/img/marker/marker_green_2x.png'),
    shadowUrl: require('public/img/marker/marker-shadow.png')
})*/

export default {
    name: 'Giro',
    components: {
        cpLead,
        loading,
        draggable,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LControlLayers,
        LControl,
        addToPlan,
        reminder
    },
    data: function () {
        return {
            loading: false,
            infoLead: false,
            infoLeadExpand: false,
            sidebar: true,

            date: null,
            plan: [],
            dragOptions: {
                animation: 0,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            },

            draggable: false,
            zoom: 14,
            center: [45.0841078, 7.4147201],
            /*url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            */
            tileProviders: [
                {
                    name: 'OpenStreetMap',
                    visible: true,
                    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                },
                {
                    name: 'OpenTopoMap',
                    visible: false,
                    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                    attribution:
                        'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                },
                {
                    name: 'Satellite',
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                }
            ],
            options: {
                minZoom: 5,
                maxZoom: 17
            },
            markers: [],
            selectedMarker: null,
            dialogDate: false,
            titleMarker: null,
            erroreDetail: null,
            deleteDialogMsg: '',
            showDeleteConfirmation: false,

            percorso: {
                distanza: 0,
                tempo: {
                    ore: 0,
                    minuti: 0
                }
            },
            days: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
            icongreen: L.icon({
                iconUrl: 'img/marker/marker_green.png',
                iconRetinaUrl: 'img/marker/marker_green_2x.png',
                shadowUrl: 'img/marker/marker-shadow.png',
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconblue: L.icon({
                iconUrl: 'img/marker/marker_blue.png',
                iconRetinaUrl: 'img/marker/marker_blue_2x.png',
                shadowUrl: 'img/marker/marker-shadow.png',
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconred: L.icon({
                iconUrl: 'img/marker/marker_red.png',
                iconRetinaUrl: 'img/marker/marker_red_2x.png',
                shadowUrl: 'img/marker/marker-shadow.png',
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconyellow: L.icon({
                iconUrl: 'img/marker/marker_yellow.png',
                iconRetinaUrl: 'img/marker/marker_yellow_2x.png',
                shadowUrl: 'img/marker/marker-shadow.png',
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            pickerDate: null,
            events: [],
            id: [],
            ids: [],
            editplan: true
        }
    },
    watch: {
        ids(newval, oldval) {
            if (!newval.length) {
                this.getLeads()
                this.getEvents(this.pickerDate)
            }
        },
        pickerDate(newval, oldval) {
            this.getEvents(newval)
        }
    },
    async mounted() {},
    methods: {
        async refresh() {
            setTimeout(async () => {
                await this.getEvents(this.pickerDate)
            }, 3000)
        },
        openDetail(lead) {
            this.selectMarker({ lead: lead })
        },
        addTo(id) {
            this.editplan = false
            this.ids = [id]
        },
        async getEvents(val) {
            if (!val) return
            const d = val.split('-') //2023-11
            const min = `${d[0]}-${d[1]}-01` //`1/${d[1]}/${d[0]}`
            let maxd = this.$moment(min).add(1, 'months').subtract(1, 'days')
            const doc = await this.$http.get(`plans`, {
                filters: {
                    agent: { id: { $eq: this.$auth.utente.agent.id } },
                    day: {
                        $between: [min, maxd.format('YYYY-MM-DD')]
                    }
                }
            })
            if (!doc.data.length) {
                this.events = []
                return
            }
            this.events = doc.data.map((x) => {
                return x.attributes.day
            })
        },
        async getLeads() {
            try {
                this.loading = true
                this.plan = []
                if (this.map && this.route) {
                    this.map.removeControl(this.route)
                }

                this.markers = []
                const doc = await this.$http.get(`plans`, {
                    filters: {
                        agent: { id: { $eq: this.$auth.utente.agent.id } },
                        day: this.date
                    },
                    populate: [
                        'agent',
                        'lead',
                        'lead.Opening',
                        'lead.Opening.mon',
                        'lead.Opening.tue',
                        'lead.Opening.wed',
                        'lead.Opening.thu',
                        'lead.Opening.fri',
                        'lead.Opening.sat',
                        'lead.Opening.sun'
                    ]
                })
                if (!doc.data.length) {
                    return
                }

                doc.data.sort((a, b) => {
                    return a.attributes.order - b.attributes.order
                })

                this.plan = doc.data.map((x) => {
                    return {
                        day: x.attributes.day,
                        agent: x.attributes.agent.data,
                        order: x.attributes.order,
                        visited: x.attributes.visited,
                        id: x.id,
                        lead: x.attributes.lead.data,
                        note: x.attributes.note,
                        coordinate: latLng(x.attributes.lead.data.attributes.Latitude, x.attributes.lead.data.attributes.Longitude)
                    }
                })
                const d = new Date(this.date)
                let dayName = this.days[d.getDay()]

                this.plan.forEach((x) => {
                    if (!x.lead.attributes.Latitude && !x.lead.attributes.Longitude) {
                        return
                    }
                    const op = x.lead.attributes.Opening[dayName]
                    let so = null
                    if (op.morningClosed && op.afternoonClosed) {
                        so = this.$t('chiuso')
                    } else if (!op.moTimeOpen && !op.afternoonClosed && !op.afTimeOpen && !op.morningClosed) {
                        so = this.$t('non-ci-sono-orari')
                    } else {
                        let mo = null
                        let af = null
                        if (op.morningClosed) {
                            mo = this.$t('chiuso')
                        } else {
                            mo = `${this.$moment(op.moTimeOpen, 'HH:mm:ss.SSS').format('HH:mm')} - ${this.$moment(op.moTimeClose, 'HH:mm:ss.SSS').format(
                                'HH:mm'
                            )} `
                        }
                        if (op.afternoonClosed) {
                            af = this.$t('chiuso')
                        } else {
                            af = `${this.$moment(op.afTimeOpen, 'HH:mm:ss.SSS').format('HH:mm')} - ${this.$moment(op.afTimeClose, 'HH:mm:ss.SSS').format(
                                'HH:mm'
                            )} `
                        }
                        so = `${mo} , ${af}`
                    }

                    this.markers.push({
                        id: x.id,
                        coordinate: latLng(x.lead.attributes.Latitude, x.lead.attributes.Longitude),
                        popup: x.lead.attributes.Name,
                        opening: x.lead.attributes.Opening,
                        optext: so
                    })
                })

                if (this.map) {
                    //this.map.setView([this.markers[0].coordinate.lat, this.markers[0].coordinate.lng], this.map.getZoom())
                    this.map.fitBounds(L.latLngBounds(this.markers.map((x) => x.coordinate)))
                }
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
                this.$nextTick(() => {
                    this.getDirection()
                })
            }
        },
        Edit() {
            if (this.draggable) {
                this.plan.forEach((x, i) => {
                    if (x.order != i) {
                        x.order = i
                        this.saveLead(x.id)
                    }
                })
                this.draggable = false
            } else {
                this.draggable = true
            }
        },
        showDeleteDialog(leadId) {
            this.id = leadId
            this.deleteDialogMsg = leadId.length == 1 ? this.$t('sei-sicuro-di-voler-eliminare-il-lead') : this.$t('sei-sicuro-di-voler-eliminare-tutti-i-lead')
            this.showDeleteConfirmation = true
        },
        async deleteLead(ids) {
            for (const id of ids) {
                await this.$http.delete(`plans/${id}`)
                this.plan = this.plan.filter((x) => {
                    return x.id != id
                })
                this.markers = this.markers.filter((x) => {
                    return x.id != id
                })
            }
            this.getEvents(this.pickerDate)
            this.showDeleteConfirmation = false
        },
        cancelDelete() {
            this.showDeleteConfirmation = false
            this.deleteDialogMsg = ''
        },
        async saveLead(id) {
            let el = this.plan.find((x) => x.id == id)
            console.log(el)
            const lead = await this.$http.put(`plans/${id}`, {
                data: {
                    order: el.order,
                    visited: el.visited,
                    note: el.note
                }
            })
        },

        selected(id) {
            const el = this.markers.find((x) => x.id == id)
            if (!el) {
                return
            }
            this.map.setView([el.coordinate.lat, el.coordinate.lng], this.map.getZoom())
        },

        getIcon(value) {
            const d = new Date(this.date)
            let dayName = this.days[d.getDay()]
            let opday = value[dayName]
            if (opday.morningClosed && opday.afternoonClosed) return this.iconred
            if (!opday.moTimeOpen && !opday.afTimeOpen) return this.iconblue

            return this.icongreen
        },
        mapReady() {
            this.map = this.$refs.myMap.mapObject
        },
        setCenter() {
            this.map.setView([45.0841078, 7.4147201], this.map.getZoom())
        },
        getCoordinate() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((currentPosition) => {
                    this.currLocation = {
                        latitude: currentPosition.coords.latitude,
                        longitude: currentPosition.coords.longitude
                    }
                    this.map.setView([this.currLocation.latitude, this.currLocation.longitude], this.map.getZoom())
                })
            } else {
                console.log('no geo')
            }
        },
        async getDirection() {
            if (!this.map) return
            let d = this.markers.map((x) => x.coordinate)

            if (this.markers.length > 1) {
                const trip = await axios.get(`https://api.insidebusiness.it/trip/v1/driving/${d.map((u) => `${u.lng},${u.lat}`).join(';')}`)
                d = trip.data.waypoints
                    .sort((a, b) => a.waypoint_index - b.waypoint_index)
                    .map((x) => {
                        return { lat: x.location[1], lng: x.location[0] }
                    })
            }
            this.percorso.distanza = 0
            this.percorso.tempo = {
                ore: 0,
                minuti: 0
            }

            if (this.route) this.map.removeControl(this.route)
            this.route = L.Routing.control({
                serviceUrl: 'https://api.insidebusiness.it/route/v1',
                waypoints: d,
                routeWhileDragging: false,
                showAlternatives: false,
                language: 'it',
                autoRoute: true,
                addWaypoints: false,
                draggableWaypoints: false,
                show: false,
                //lineOptions: { styles: [{ color: 'black', opacity: 0, weight: 9 }] }, //con questo style nascondiamo il percorso
                createMarker: function (i, waypoint, n) {
                    return false
                }
            }).addTo(this.map)

            var self = this
            this.route.on('routesfound routeselected', function (e) {
                let summary = null
                if (e.routes) {
                    summary = e.routes[0].summary
                    //console.log('route', summary)
                } else {
                    summary = e.route.summary
                    //console.log('route', e.route)
                }

                let time = self.toHoursAndMinutes(summary.totalTime)
                //console.log(summary, Math.round(summary.totalDistance / 1000), time)
                self.percorso.distanza = (summary.totalDistance / 1000).toFixed(1)
                self.percorso.tempo = {
                    ore: time.h,
                    minuti: time.m
                }
                // alert distance and time in km and minutes
                //alert('Total distance is ' + Math.round(summary.totalDistance / 1000) + '  and total time is ' + time.h + ':' + time.m + ' ')
            })
        },
        toHoursAndMinutes(totalSeconds) {
            const totalMinutes = Math.floor(totalSeconds / 60)

            const seconds = totalSeconds % 60
            const hours = Math.floor(totalMinutes / 60)
            const minutes = totalMinutes % 60

            return { h: hours, m: minutes, s: seconds }
        },
        closeDetails() {
            console.log('entrato')
            this.selectedMarker = null
            this.infoLead = false
            this.infoLeadExpand = false
        },
        selectMarker(el) {
            this.selectedMarker = el.lead.id
            this.titleMarker = el.lead.attributes.Name
            this.infoLead = !this.infoLead
        },
        errorDetails(err) {
            console.log('err', err)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
.map {
    margin-top: 30px;
    height: 35vh;
    width: 100%;
    z-index: 0;
    position: sticky;
}
@media (max-width: 768px) {
    .map {
        margin-top: 0px;
        margin-bottom: 20px;
        height: 30vh;
        width: 100%;
        z-index: 0;
        position: sticky;
    }
}
.handle {
    cursor: move;
}

.v-btn:not(.v-btn--round).v-size--x-small {
    border: 2px solid rgba(0, 0, 0, 0.2);
}
.button_location {
    width: 30px !important;
    height: 40px !important;
    color: #1976d2 !important;
}

.v-btn {
    border: 0px !important;
}
.theme--light.v-expansion-panels .v-expansion-panel--disabled {
    color: rgb(0, 0, 0);
}
.btn-disabled {
    pointer-events: none;
}
span {
    text-transform: uppercase;
}
</style>
