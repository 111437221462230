<template>
    <v-app>
        <template v-if="!maintenance">
            <notifications group="notifica" position="bottom right" />
            <DHeader v-if="$auth.user" />

            <v-main>
                <router-view />
                <!-- Scroll to top button -->
                <v-btn v-if="scrollY" @click="scrollToTop" fixed bottom right fab small color="primary">
                    <v-icon>arrow_upward</v-icon>
                </v-btn>
            </v-main>
        </template>
        <template v-if="maintenance">
            <div class="d-flex flex-column justify-center align-center" style="height: 100%">
                <img src="/img/logodam.png" style="width: 25vw; margin-bottom: 3vh; max-width: 370px" />
                <div class="display-1 font-weight-black blue--text">{{ $t('attendere') }}</div>
                <div class="subtitle-1 font-weight-bold">{{ $t('se-la-pagina-non-viene-aggiornata-controllare-la-connessione') }}</div>
            </div>
        </template>
    </v-app>
</template>

<script>
import DHeader from '@/components/Header.vue'

export default {
    name: 'App',
    components: {
        DHeader
    },
    sockets: {
        connect() {
            console.log('Connected to server')
            //this.maintenance = false
        },
        disconnect() {
            console.log('Disconnected from server')
            this.maintenance = true
        },
        maintenance(data) {
            console.log('socket update maintenance', data.data.maintenance)
            //this.$nextTick(() => {
            this.maintenance = data.data.maintenance
            //})
        }
    },
    data() {
        return { scrollY: false, maintenance: false }
    },
    async beforeMount() {
        /*let self = this
        self.maintenance = await self.getMaintenance()
        setInterval(async function () {
            let m = await self.getMaintenance()
            if (self.maintenance == true && m == false) {
                self.$forceUpdate()
                //self.$router.go(0)
            }
            self.maintenance = m
        }, 30000)*/
    },
    async mounted() {
        window.addEventListener('scroll', this.handleScroll)

        if (this.$auth.user) {
            if (!(await this.$auth.checkUser())) {
                this.$router.go(this.$router.currentRoute)
            }
        }
        /*setTimeout(() => {
            this.maintenance = !this.$socket.connected
        }, 1000)*/

        await this.$nextTick()
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Map') {
                this.disableZoom(true)
            } else if (from.name === 'Map') {
                this.disableZoom(false)
            }
        }
    },
    methods: {
        /*async getMaintenance() {
            const setting = await this.$http.get(`setting`, {})
            if (setting.data) {
                return setting.data.attributes.maintenance
            } else {
                return false
            }
        },*/
        //disabilitato lo zoom
        disableZoom(val) {
            let v = 'auto'
            if (val) {
                v = 'none'
            }
            document.documentElement.style.userSelect = v
            document.documentElement.style.webkitUserDrag = v
            document.documentElement.style.touchAction = v
            document.documentElement.style.msTouchAction = v
        },
        handleScroll() {
            if (window.scrollY > 50) {
                this.scrollY = true
            } else {
                this.scrollY = false
            }
        },

        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }
}
</script>
<style>
a {
    text-decoration: none;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
    margin: 10px;
    border: 1px solid #7b7b7b;
    display: block;
}
</style>
